import { useState } from "react";
import Page from "src/components/page/page";
import { useLocales } from "src/locales";
import { Button, TextField } from "@mui/material";
import { CustomDataGrid } from "src/components/customComponents/dataGrid/CustomDataGrid";
import {
  QueryType,
  useDataGridRef,
  FetchingDataEnum,
} from "@novin-npm/shared-components-front";
import { capitalize, tableStateToArray } from "src/utils/utils";
import { useGetAllMessageHistoryNotification } from "src/hooks/query&mutation/Notification/useGetAllMessageHistoryNotification";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import moment from "moment";
import { ConfirmDialog } from "src/components/custom-dialog";
import { GeneralDialogState } from "src/components/dialog/SimpleDialog";
import { Stack } from "@mui/system";
function MessageNotificationHistory() {
  const { translate, currentLang } = useLocales();
  const tableRef = useDataGridRef();
  const [tableState, setTableState] = useState<QueryType>();
  const [messageDetailDialog, setMessageDetailDialog] = useState<
    GeneralDialogState<string>
  >({
    show: false,
    data: "",
  });
  const { data, isLoading, isError, isFetching, refetch } =
    useGetAllMessageHistoryNotification({
      tableState: tableStateToArray(tableState),
    });

  return (
    <Page title={translate("NOTIFICATION_HISTORY_SMS_NOTIFICATION")}>
      <CustomDataGrid
        tableRef={tableRef}
        columns={[
          {
            field: "title",
            type: "string",
            headerName: translate("NOTIFICATION_HISTORY_TITLE"),
            flex: 1,
            minWidth: 100,
            valueFormatter: ({ value }) =>
              capitalize(value?.split("_").join(" ")),
          },
          {
            field: "data",
            type: "string",
            headerAlign: "left",
            align: "left",
            headerName: translate("NOTIFICATION_HISTORY_DATA"),
            valueFormatter: ({ value }) =>
              JSON.stringify(JSON.parse(value), null, 4),
            flex: 2,
            minWidth: 200,
          },
          {
            field: "updatedAt",
            type: "date",
            headerName: translate("SERVICE_UPDATE_AT"),
            flex: 1,
            minWidth: 100,
            valueGetter: (params) => new Date(params.value),
            valueFormatter: ({ value }) =>
              moment(value)
                .locale(currentLang.value)
                .format("YYYY/MM/DD , HH:MM"),
          },
          {
            field: "createdAt",
            type: "date",
            headerName: translate("SERVICE_CREATED_AT"),
            flex: 1,
            minWidth: 100,
            valueGetter: (params) => new Date(params.value),
            valueFormatter: ({ value }) =>
              moment(value)
                .locale(currentLang.value)
                .format("YYYY/MM/DD , HH:MM"),
          },
          {
            field: "isSeen",
            type: "string",
            headerName: translate("NOTIFICATION_HISTORY_IS_SEEEN"),
            flex: 1,
            minWidth: 50,
            renderCell: ({ row }) =>
              row.isSeen ? (
                <DoneIcon color="success" />
              ) : (
                <ClearIcon color="error" />
              ),
          },
        ]}
        isFetching={isFetching}
        dataFetching={{
          type: FetchingDataEnum.MANUAL,
          loading: isLoading,
          error: isError,
          onRetry: refetch,
          data: {
            items: data?.data || [],
            rowCount: data?.meta?.total || 0,
          },
        }}
        onRowClick={(params, event) => {
          setMessageDetailDialog({
            show: true,
            data: params?.row?.data || "",
          });
        }}
        disableRowSelectionOnClick={true}
        onQueryUpdate={(query) => {
          setTableState(query);
        }}
        pageSizeOptions={[10, 20, 50, 100]}
        slotProps={{
          row: {
            style: {
              cursor: "pointer",
            },
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
      <ConfirmDialog
        title={translate("NOTIFICATION_HISTORY_MESSAGE_DETAIL")}
        maxWidth="md"
        content={
          <Stack mt={2}>
            <TextField
              disabled
              label={"NOTIFICATION_HISTORY_DATA"}
              value={messageDetailDialog?.data}
              multiline
              rows={4}
            />
          </Stack>
        }
        action={null}
        open={messageDetailDialog?.show}
        onClose={() => setMessageDetailDialog({ data: "", show: false })}
      />
    </Page>
  );
}

export default MessageNotificationHistory;
