import { ReactNode } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { LoadingScreen } from "src/components/loading-screen";

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { initialized, keycloak } = useKeycloak();

  if (!initialized) {
    return <LoadingScreen />;
  }

  if (!keycloak.authenticated) {
    keycloak.login();
  }

  return <>{children}</>;
}
