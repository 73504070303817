export const QUERY_KEYS = {
  MY_PROFILE: "my_profile",
  MY_PERMISSIONS: "my_permissions",
  USER_COMPANY: "user_company",
  DASHBOARD: {
    DASHBOARD_STATICS_LIST: "dashboard_statics_list",
    DASHBOARD_FOLDER_STATICS_LIST: "dashboard_folder_statics_list",
  },
  USER: {
    USERS_LIST: "user_list",
    SINGLE_USER: "single_user",
    ORGANIZATIONS_LIST: "organizations_list",
  },
  ORGANIZATIONS: {
    ORGANIZATIONS_LIST: "organizations_list",
    ORGANIZATION_INFO: "organization_info",
    ORGANIZATION_USERS_LIST: "organization_users_list",
    ORGANIZATION_GROUPS_LIST: "organization_groups_list",
    ORGANIZATION_SINGLE_GROUP_MEMBERS: "organization_single_group_members",
    ORGANIZATION_SERVICES_LIST: "organization_services_list",
  },
  NOTIFICATION: {
    ADD_NOTIFICATION: "add_notification",
    NOTIFICATION_LIST: "notification_list",
    NOTIFICATION_MESSAGE_HISTORY_LIST: "notification_message_history_list",
    NOTIFICATION_EMAIL_HISTORY_LIST: "notification_email_history_list",
    NOTIFICATION_SMS_HISTORY_LIST: "notification_sms_history_list",
  },
  SERVICES: {
    SERVICES_LIST: "service_list",
    SINGLE_SERVICE: "single_service",
    SERVICE_PERMISSIONS_LIST: "service_permissions_list",
    SERVICE_RECOURSES_LIST: "service_recourses_list",
    SERVICE_COMPANIES_LIST: "service_companies_list",
    HEALTH_SERVICE: "health_service",
    HEALTH_SERVICE_INFO: "health_service_info",
  },
  ROLE: {
    ROLES_LIST: "role-list",
    SINGLE_ROLE: "single_role",
    PUBLIC_ROLES_LIST: "public_role_list",
    ROLE_USERS_LIST: "role_users_list",
    ROLE_PERMISSIONS_LIST: "role_permissions_list",
  },
  PERMISSION_SET: {
    LIST: "permission_set_list",
    SINGLE: "single_permission_set",
  },
  FILE_EXPLORER: { FOLDER_CONTENT: "folder-content" },
  BLOCKCHAIN: {
    BLOCKCHAIN_LIST: "blockchain-list",
    SINGLE_BLOCKCHAIN: "single_blockchain",
    BLOCKCHAIN_CURRENCIES: "blockchain-currencies",
    BLOCKCHAIN_TRANSACTIONS: "blockchain-transactions",
    BLOCKCHAIN_MAIN_HEIGHT: "blockchain-main-height",
    GET_BLOCK: "get-block",
    GET_TRANSACTION: "get-transaction",
    WALLET_LIST: "wallet-list",
    SINGLE_WALLET: "single-wallet",
  },
  ATTRIBUTES_LIST: "attributes_list",
  COUNTRY_LIST: "country_list",
  CURRENCY_LIST: "currency_list",
  INDUSTRIES_LIST: "industries_list",
  TEAM_SIZES: "team_sizes",
  PERMISSIONS_LIST: "permissions_list",
  LOG: {
    LOGS_LIST: "logs_list",
    SINGLE_LOG: "single_log",
    MONITOR_LOGS: "monitor_logs_list",
    REPORT_LOGS: "report_logs_list",
    DB_Logs: "database_logs",
  },
  SERVICE: {
    SERVICES_LIST: "services_list",
    SINGLE_SERVICE: "single_service",
  },
  REPORTS: {
    REPORT_TYPES: "report_type",
  },
  DICTIONARY: {
    DICTIONARY_LIST: "dictionary_list",
  },
};
