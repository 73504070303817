import { useState } from "react";
import Page from "src/components/page/page";
import { useLocales } from "src/locales";
import { Stack, TextField } from "@mui/material";
import { CustomDataGrid } from "src/components/customComponents/dataGrid/CustomDataGrid";
import {
  QueryType,
  useDataGridRef,
  FetchingDataEnum,
} from "@novin-npm/shared-components-front";
import { capitalize, tableStateToArray } from "src/utils/utils";
import { useGetAllSmsHistoryNotification } from "src/hooks/query&mutation/Notification/useGetAllSmsHistoryNotification";
import moment from "moment";
import { ConfirmDialog } from "src/components/custom-dialog";
import { GeneralDialogState } from "src/components/dialog/SimpleDialog";
function SmsNotificationHistory() {
  const { translate, currentLang } = useLocales();
  const [smsDetailDialog, setSmsDetailDialog] = useState<
    GeneralDialogState<string>
  >({
    show: false,
    data: "",
  });
  const tableRef = useDataGridRef();
  const [tableState, setTableState] = useState<QueryType>();
  const { data, isLoading, isError, isFetching, refetch } =
    useGetAllSmsHistoryNotification({
      tableState: tableStateToArray(tableState),
    });
  return (
    <Page title={translate("NOTIFICATION_HISTORY_MESSAGE_NOTIFICATION")}>
      <CustomDataGrid
        tableRef={tableRef}
        columns={[
          {
            field: "type",
            type: "string",
            headerName: translate("REPORTS_TYPE"),
            flex: 1,
            minWidth: 50,
            valueFormatter: ({ value }) => capitalize(value),
          },
          {
            field: "createdByUserCompany.user.fullName",
            type: "string",
            headerName: translate("NOTIFICATION_HISTORY_CREATEDBY"),
            flex: 1,
            minWidth: 100,
          },
          {
            field: "recipient",
            type: "number",
            headerName: translate("NOTIFICATION_HISTORY_RECIPIENT"),
            flex: 1,
            minWidth: 100,
          },
          {
            field: "message",
            type: "string",
            headerAlign: "left",
            align: "left",
            headerName: translate("NOTIFICATION_HISTORY_MESSAGE"),
            flex: 1,
            minWidth: 100,
          },
          {
            field: "createdByUserCompany.company.name",
            type: "string",
            headerAlign: "left",
            align: "left",
            headerName: translate("NOTIFICATION_HISTORY_SENDER_COMPANY"),
            flex: 1,
            minWidth: 100,
          },
          {
            field: "updatedAt",
            type: "date",
            headerName: translate("SERVICE_UPDATE_AT"),
            flex: 1,
            minWidth: 100,
            valueGetter: (params) => new Date(params.value),
            valueFormatter: ({ value }) =>
              moment(value)
                .locale(currentLang.value)
                .format("YYYY/MM/DD , HH:MM"),
          },
          {
            field: "createdAt",
            type: "date",
            headerName: translate("SERVICE_CREATED_AT"),
            flex: 1,
            minWidth: 100,
            valueGetter: (params) => new Date(params.value),
            valueFormatter: ({ value }) =>
              moment(value)
                .locale(currentLang.value)
                .format("YYYY/MM/DD , HH:MM"),
          },
        ]}
        isFetching={isFetching}
        dataFetching={{
          type: FetchingDataEnum.MANUAL,
          loading: isLoading,
          error: isError,
          onRetry: refetch,
          data: {
            items: data?.data || [],
            rowCount: data?.meta?.total || 0,
          },
        }}
        onRowClick={(params, event) => {
          setSmsDetailDialog({
            show: true,
            data: params?.row?.message || "",
          });
        }}
        disableRowSelectionOnClick={true}
        onQueryUpdate={(query) => {
          setTableState(query);
        }}
        pageSizeOptions={[10, 20, 50, 100]}
        slotProps={{
          row: {
            style: {
              cursor: "pointer",
            },
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
      <ConfirmDialog
        title={translate("NOTIFICATION_HISTORY_SMS_DETAIL")}
        maxWidth="md"
        content={
          <Stack mt={2}>
            <TextField
              disabled
              label={translate("NOTIFICATION_HISTORY_MESSAGE")}
              value={smsDetailDialog?.data}
              multiline
              rows={4}
            />
          </Stack>
        }
        action={null}
        open={smsDetailDialog?.show}
        onClose={() => setSmsDetailDialog({ data: "", show: false })}
      />
    </Page>
  );
}

export default SmsNotificationHistory;
