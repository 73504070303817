import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import { useSettingsContext } from "src/components/settings";
import IconButtonAnimate from "src/components/animate/IconButtonAnimate";

export default function LightDarkButton() {
  const { onUpdate, themeMode } = useSettingsContext();
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    onUpdate("themeMode", themeMode === "light" ? "dark" : "light");
  };

  return (
    <IconButtonAnimate
      onClick={handleOpen}
      sx={{
        width: 40,
        height: 40,
      }}
    >
      {themeMode === "light" ? (
        <DarkModeRoundedIcon />
      ) : (
        <LightModeRoundedIcon />
      )}
    </IconButtonAnimate>
  );
}
