import axios, { AxiosRequestConfig } from "axios";
import { SENTRY_API } from "src/config-global";
import {
  CompanyCurrentControllerCompanyUsers200ResponseMeta,
  CompanyResponseDto,
  CompanyServiceDto,
  CreateGroupDto,
  CreateRoleDto,
  CreateRolePermissionsDto,
  CreateUserCompanyDto,
  CreateUserDto,
  GroupResponseDto,
  PermissionDto,
  ResourceResponseDto,
  RolePermissionResponseDto,
  RoleResponseDto,
  ServiceResponseDto,
  UpdateCompanyDto,
  UpdateCompanyServiceDto,
  UpdateGroupDto,
  UpdateRoleDto,
  UpdateUserCompanyDto,
  UpdateUserDto,
  UpdateUserRoleDto,
  UserCompaniesResponseDto,
  UserCompanyResponseDto,
  UserDto,
  UserProfileDto,
} from "./models/sentryModel";
import { UpdateServiceDto } from "./models/logModel";

export type GeneralList<T> = {
  data: Array<T>;
  meta: CompanyCurrentControllerCompanyUsers200ResponseMeta;
};

export type GetListDto = {
  sort?: string;
  select?: string[];
  filter?: string[];
  or?: string[];
  page?: number;
  skip?: number;
  take?: number;
  excel?: string;
};

const instance = axios.create({
  baseURL: SENTRY_API,
});

export default {
  instance,

  // DASHBOARD
  getAllStatics: (params?: GetListDto) =>
    instance.get("statics/dashboard", { params }).then((res) => res.data),

  // GENERAL
  getMyProfile: () =>
    instance
      .get<UserProfileDto>("users/current-user/profile")
      .then((res) => res.data),
  getPermissions: ({
    page,
    take,
    search,
  }: {
    page?: number;
    take?: number;
    search?: string;
  }) =>
    instance
      .get<GeneralList<PermissionDto>>("permissions", {
        params: {
          page,
          take,
          filter: search ? [`displayName||$contL||${search}`] : [],
        },
      })
      .then((res) => res.data),
  getFileBase64: (url: string, id?: string) =>
    instance
      .get<Buffer>(url, {
        responseType: "arraybuffer",
        ...(!!id && {
          id,
          cache: {
            ttl: 60 * 60 * 1000, // 1 hour
          },
        }),
      })
      .then((res) => {
        let binary = "";
        let bytes = new Uint8Array(res.data);
        const blob = new Blob([bytes]);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return {
          base64: `data:${res.headers["content-type"]};base64,${window.btoa(
            binary
          )}`,
          originalName: res.headers["content-disposition"].split("=")[1],
          size: blob.size,
        };
      }),

  //USERS
  getUsers: (params?: GetListDto) =>
    instance
      .get<GeneralList<UserDto>>("users", { params })
      .then((res) => res.data),
  getSingleUser: (id: string) =>
    instance.get<UserDto>(`users/${id}`).then((res) => res.data),
  createUser: (data: CreateUserDto) =>
    instance.post("users", data).then((res) => res?.data),
  changeUserAvatar: (
    file: File | null,
    id: string,
    config: AxiosRequestConfig
  ) =>
    instance
      .patch(`users/${id}/avatar`, { file }, config)
      .then((res) => res.data),
  updateUser: (id: string, data: UpdateUserDto) =>
    instance.patch(`users/${id}`, data).then((res) => res.data),
  activeUser: (id: string) =>
    instance.patch(`users/${id}/enable`).then((res) => res.data),
  deActiveUser: (id: string) =>
    instance.patch(`users/${id}/disable`).then((res) => res.data),
  getUserOrganizations: (id: string, params?: GetListDto) =>
    instance
      .get<GeneralList<UserCompaniesResponseDto>>(`users/${id}/companies`, {
        params,
      })
      .then((res) => res.data),
  updateUserSingleOrganization: (
    userId: string,
    companyId: string,
    data: UpdateUserCompanyDto
  ) =>
    instance
      .patch<UserCompanyResponseDto>(
        `users/${userId}/companies/${companyId}`,
        data
      )
      .then((res) => res.data),
  updateUserPassword: (userId: string, data: any) =>
    instance
      .patch<UserCompanyResponseDto>(`users/${userId}/change-password`, data)
      .then((res) => res.data),
  activeUserOrganization: (userId: string, companyId: string) =>
    instance
      .patch(`users/${userId}/companies/${companyId}/restore`)
      .then((res) => res.data),
  deActiveUserOrganization: (userId: string, companyId: string) =>
    instance
      .patch(`users/${userId}/companies/${companyId}/disable`)
      .then((res) => res.data),

  //ORGANIZATIONS
  getOrganizations: (params?: GetListDto) =>
    instance
      .get<GeneralList<CompanyResponseDto>>("companies", { params })
      .then((res) => res.data),
  getSingleOrganization: (id: string) =>
    instance.get<CompanyResponseDto>(`companies/${id}`).then((res) => res.data),
  updateOrganization: (id: string, data: UpdateCompanyDto) =>
    instance
      .patch<CompanyResponseDto>(`companies/${id}`, data)
      .then((res) => res.data),
  getOrganizationMembers: (companyId: string, params?: GetListDto) =>
    instance
      .get<GeneralList<UserDto>>(`companies/${companyId}/users`, { params })
      .then((res) => res.data),
  addUserToOrganization: (userId: string, data: CreateUserCompanyDto) =>
    instance
      .post<CreateUserCompanyDto>(`users/${userId}/companies`, data)
      .then((res) => res.data),
  getInfinityOrganizationUsers: (
    companyId: string,
    params: { page: number; take: number }
  ) =>
    instance
      .get<GeneralList<UserDto>>(`companies/${companyId}/users`, { params })
      .then((res) => res.data),
  activeUserOfOrganization: (usrId: string, companyId: string) =>
    instance
      .patch(`users/${usrId}/companies/${companyId}/restore`)
      .then((res) => res.data),
  deActiveUserOfOrganization: (usrId: string, companyId: string) =>
    instance
      .patch(`users/${usrId}/companies/${companyId}/disable`)
      .then((res) => res.data),
  getOrganizationGroups: (companyId: string, params?: GetListDto) =>
    instance
      .get<GeneralList<GroupResponseDto>>(`groups/companies/${companyId}`, {
        params,
      })
      .then((res) => res.data),
  createOrganizationGroup: (companyId: string, data: CreateGroupDto) =>
    instance
      .post(`groups/companies/${companyId}`, data)
      .then((res) => res.data),
  deleteOrganizationGroup: (companyId: string, groupId: string) =>
    instance
      .delete(`groups/${groupId}/companies/${companyId}`)
      .then((res) => res.data),
  updateOrganizationGroup: (
    id: string,
    companyId: string,
    data: UpdateGroupDto
  ) =>
    instance
      .patch(`groups/${id}/companies/${companyId}`, data)
      .then((res) => res.data),
  getOrganizationServices: (companyId: string, params?: GetListDto) =>
    instance
      .get<GeneralList<CompanyServiceDto>>(`companies/${companyId}/services`, {
        params,
      })
      .then((res) => res.data),
  updateOrganizationService: (
    companyId: string,
    applicationId: string,
    data: UpdateCompanyServiceDto
  ) =>
    instance
      .patch(`companies/${companyId}/applications/${applicationId}`, data)
      .then((res) => res.data),

  //SERVICES
  getServices: (params?: GetListDto) =>
    instance
      .get<GeneralList<ServiceResponseDto>>("services", { params })
      .then((res) => res.data),
  updateService: (serviceId: string, data: UpdateServiceDto) =>
    instance.patch(`services/${serviceId}`, data).then((res) => res.data),
  getSingleService: (serviceId: string) =>
    instance
      .get<ServiceResponseDto>(`services/${serviceId}`)
      .then((res) => res.data),
  getServicePermissions: (id: string, params?: GetListDto) =>
    instance
      .get<GeneralList<PermissionDto>>("permissions", {
        params: {
          ...params,
          filter: [`resource.serviceId||$eq||${id}`, ...(params?.filter || [])],
          select: ["displayName", "resource.id", "resource.name", "scope"],
        },
      })
      .then((res) => res.data),
  getServiceRecourses: (id: string, params?: GetListDto) =>
    instance
      .get<GeneralList<ResourceResponseDto>>("resources", {
        params: {
          ...params,
          filter: [`service.id||$eq||${id}`, ...(params?.filter || [])],
          select: ["name"],
        },
      })
      .then((res) => res.data),
  getServiceCompanies: (serviceId: string, params?: GetListDto) =>
    instance
      .get<GeneralList<ResourceResponseDto>>(
        `services/${serviceId}/companies`,
        {
          params,
        }
      )
      .then((res) => res.data),
  getHealthService: (serviceName: string) =>
    instance.get(`${serviceName}/health/check`).then((res) => res.data),

  //ACRES CONTROL

  //ROLES
  getRoles: (params?: GetListDto) =>
    instance
      .get<GeneralList<RoleResponseDto>>("roles", { params })
      .then((res) => res.data),
  getSingleRole: (id: string) =>
    instance.get<RoleResponseDto>(`roles/${id}`).then((res) => res.data),
  createRole: (data: CreateRoleDto) =>
    instance.post("roles", data).then((res) => res.data),
  getUsersRole: (id: string, params?: GetListDto) =>
    instance
      .get<GeneralList<any>>(`roles/${id}/users`, { params })
      .then((res) => res.data),
  updateUserRole: (roleId: string, userId: string, data: UpdateUserRoleDto) =>
    instance
      .patch(`users/${userId}/roles/${roleId}`, data)
      .then((res) => res.data),
  getRolePermissions: (id: string, params?: GetListDto) =>
    instance
      .get<GeneralList<RolePermissionResponseDto>>(`roles/${id}/permissions`, {
        params,
      })
      .then((res) => res.data),
  deleteRole: (id: string) =>
    instance.delete(`roles/${id}`).then((res) => res.data),
  updateRole: (id: string, data: UpdateRoleDto) =>
    instance.patch(`roles/${id}`, data).then((res) => res.data),
  addPermissionsToRole: (id: string, data: CreateRolePermissionsDto) =>
    instance.post(`roles/${id}/permissions`, data).then((res) => res.data),
  deletePermissionOfRole: (roleId: string, permissionIds: string[]) =>
    instance
      .delete(`roles/${roleId}/permissions`, { data: { permissionIds } })
      .then((res) => res.data),

  //Permission Set
  getPermissionSets: (params: GetListDto) =>
    instance
      .get<GeneralList<any>>("/permission-set", { params })
      .then((res) => res.data),
  getSinglePermission: (id: string) =>
    instance.get<any>(`/permission-set/${id}`).then((res) => res.data),
  createPermissionSet: (data: any) =>
    instance.post("/permission-set", data).then((res) => res.data),
  editPermissionSet: ({ id, data }: { id: string; data: any }) =>
    instance.patch(`/permission-set/${id}`, data).then((res) => res.data),
  deletePermissionSet: (id: string) =>
    instance.delete(`/permission-set/${id}`).then((res) => res.data),
};
