import { palette } from "../palette";
import { shadows } from "../shadows";
import { customShadows } from "../custom-shadows";

// ----------------------------------------------------------------------

export function darkMode(mode: "light" | "dark") {
  return {
    palette: palette(mode),
    shadows: shadows(mode),
    customShadows: customShadows(mode),
  };
}
