import { m } from "framer-motion";
import { MouseEvent } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// routes
import { ABSOLUTE_ROUTES } from "src/routes/paths";
// components
import { varHover } from "src/components/animate";
import CustomPopover, { usePopover } from "src/components/custom-popover";
import { useKeycloak } from "@react-keycloak/web";
import { useMyProfile } from "src/hooks/query&mutation/profile/useMyProfile";
import { Skeleton } from "@mui/material";
import { CustomAvatar } from "src/components/custom-avatar/CustomAvatar";
// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: "Home",
    linkTo: ABSOLUTE_ROUTES.BASE,
  },
  {
    label: "Security",
    linkTo: `${import.meta.env.VITE_KEYCLOAK_URL}/realms/${
      import.meta.env.VITE_KEYCLOAK_REALM
    }/account`,
    target: "_blank",
    onClick: function () {
      window.open(this.linkTo, this.target);
    },
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { keycloak } = useKeycloak();
  const { data, isLoading } = useMyProfile();
  const theme = useTheme();

  const popover = usePopover();

  const handleLogout = () => {
    keycloak.logout();
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <CustomAvatar
          url={`${import.meta.env.VITE_HOST_API}users/${data?.profile?.id}/avatar`}
          fileName={""}
          type={"avatar_letter"}
          sx={{
            width: 36,
            height: 36,
            border: () => `solid 2px ${theme.palette.background.default}`,
          }}
        />
      </IconButton>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 200, p: 0 }}
      >
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {isLoading ? (
              <Skeleton sx={{ bgcolor: "grey.100" }} width={120} />
            ) : (
              data?.profile?.firstName + " " + data?.profile?.lastName
            )}
          </Typography>

          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {isLoading ? (
              <Skeleton sx={{ bgcolor: "grey.100" }} width={120} />
            ) : (
              data?.profile?.email
            )}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              target={option.target}
              rel={
                option.target === "_blank" ? "noreferrer noopener" : undefined
              }
              onClick={(event: MouseEvent<HTMLElement>) => {
                popover.onClose();
                if (option.onClick) {
                  event.preventDefault();
                  option.onClick();
                }
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: "fontWeightBold", color: "error.main" }}
        >
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  );
}
