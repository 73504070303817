import { lazy, Suspense } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "src/layouts/dashboard";
// components
import { LoadingScreen } from "src/components/loading-screen";
import AuthGuard from "src/guards/AuthGuard";
import NotificationsManagement from "src/pages/Notification/NotificationManagement/NotificationManagement";
import { HistoryNotification } from "src/pages/Notification/NotificationHistory/HistoryNotification";
import { ROUTES } from "./paths";
import NotFoundPage from "../pages/404";

// ----------------------------------------------------------------------

// Dashboard
const Dashboard = lazy(() => import("src/pages/dashboard/dashboard"));

// Users
const UsersPage = lazy(() => import("src/pages/users/UsersList"));
const UserProfile = lazy(() => import("src/pages/users/UserProfile"));

//Services
const ServicesPage = lazy(() => import("src/pages/services/ServicesList"));
const DetailServicePage = lazy(
  () => import("src/pages/services/ServiceDetail")
);

//ORGANIZATION
const OrganizationPage = lazy(
  () => import("src/pages/organizations/OrganizationsList")
);
const OrganizationProfile = lazy(
  () => import("src/pages/organizations/OrganizationProfile")
);

//Roles
const RolesPage = lazy(() => import("src/pages/accessControl/roles/RolesList"));
const DetailRolePage = lazy(
  () => import("src/pages/accessControl/roles/RoleDetail")
);

//Permission sets
const PermissionSetsPage = lazy(
  () => import("src/pages/accessControl/permissionSets/PermissionSetsList")
);
const PermissionSetDetailPage = lazy(
  () => import("src/pages/accessControl/permissionSets/PermissionSetDetail")
);

//FILE EXPLORER
const FileExplorerPage = lazy(
  () => import("src/pages/fileExplorer/FileExplorer")
);

//Logs
const LogsPage = lazy(() => import("src/pages/logs/LogsList"));
const LogDetailPage = lazy(() => import("src/pages/logs/LogDetail"));

//BLOCKCHAIN
const BlockchainManagementListPage = lazy(
  () => import("src/pages/blockChain/management/BlockchainManagementList")
);
const DetailBlockchainPage = lazy(
  () => import("src/pages/blockChain/management/BlockchainDetail")
);
const BlockchainWalletListPage = lazy(
  () => import("src/pages/blockChain/wallet/BlockchainWalletList")
);
const WalletDetailPage = lazy(
  () => import("src/pages/blockChain/wallet/WalletDetail")
);
const BlockchainCurrenciesListPage = lazy(
  () => import("src/pages/blockChain/currencies/CurrenciesListPage")
);

//Reports
const ReportsPage = lazy(() => import("src/pages/reports/Reports"));

//Database Logs
const DBLogsPage = lazy(
  () => import("src/pages/databaseLogs/DatabaseLogsList")
);

//Monitoring
const MonitoringPage = lazy(() => import("src/pages/monitoring/Monitoring"));

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: ROUTES.BASE,
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ),
      children: [
        {
          element: <Dashboard />,
          index: true,
        },
        {
          path: ROUTES.USERS.BASE,
          children: [
            {
              element: <UsersPage />,
              index: true,
            },
            {
              path: ROUTES.USERS.SINGLE_USER.BASE,
              element: (
                <Navigate to={ROUTES.USERS.SINGLE_USER.ORGANIZATION} replace />
              ),
              index: true,
            },
            {
              path: ROUTES.USERS.SINGLE_USER.BASE,
              children: [
                {
                  element: <UserProfile type="Organizations" />,
                  path: ROUTES.USERS.SINGLE_USER.ORGANIZATION,
                },
                {
                  element: <UserProfile type="Logs" />,
                  path: ROUTES.USERS.SINGLE_USER.LOGS,
                },
              ],
            },
          ],
        },
        {
          path: ROUTES.ORGANIZATION.BASE,
          children: [
            {
              element: <OrganizationPage />,
              index: true,
            },
            {
              path: ROUTES.ORGANIZATION.SINGLE_ORGANIZATION.BASE,
              children: [
                {
                  element: (
                    <Navigate
                      to={ROUTES.ORGANIZATION.SINGLE_ORGANIZATION.MEMBERS}
                      replace
                    />
                  ),
                  index: true,
                },
                {
                  element: <OrganizationProfile type="Members" />,
                  path: ROUTES.ORGANIZATION.SINGLE_ORGANIZATION.MEMBERS,
                },
                {
                  element: <OrganizationProfile type="Teams" />,
                  path: ROUTES.ORGANIZATION.SINGLE_ORGANIZATION.TEAMS,
                },
                {
                  element: <OrganizationProfile type="Services" />,
                  path: ROUTES.ORGANIZATION.SINGLE_ORGANIZATION.SERVICES,
                },
              ],
            },
          ],
        },
        {
          path: ROUTES.NOTIFICATION.BASE,
          children: [
            {
              element: <NotificationsManagement />,
              index: true,
            },
            {
              element: <NotificationsManagement />,
              path: ROUTES.NOTIFICATION.MANAGEMENT,
            },
            {
              path: ROUTES.NOTIFICATION.HISTORY.BASE,
              children: [
                {
                  element: (
                    <Navigate to={ROUTES.NOTIFICATION.HISTORY.SMS} replace />
                  ),
                  index: true,
                },
                {
                  element: <HistoryNotification type="SMS" />,
                  path: ROUTES.NOTIFICATION.HISTORY.SMS,
                },
                {
                  element: <HistoryNotification type="MAIL" />,
                  path: ROUTES.NOTIFICATION.HISTORY.MAIL,
                },
                {
                  element: <HistoryNotification type="MESSAGE" />,
                  path: ROUTES.NOTIFICATION.HISTORY.MESSAGE,
                },
              ],
            },
          ],
        },
        {
          path: ROUTES.SERVICES.BASE,
          children: [
            {
              element: <ServicesPage />,
              index: true,
            },
            {
              path: ROUTES.SERVICES.SINGLE_SERVICE.BASE,
              children: [
                {
                  element: (
                    <Navigate
                      to={ROUTES.SERVICES.SINGLE_SERVICE.PERMISSIONS}
                      replace
                    />
                  ),
                  index: true,
                },
                {
                  element: <DetailServicePage type="Permissions" />,
                  path: ROUTES.SERVICES.SINGLE_SERVICE.PERMISSIONS,
                },
                {
                  element: <DetailServicePage type="Recourses" />,
                  path: ROUTES.SERVICES.SINGLE_SERVICE.RESOURCES,
                },
                {
                  element: <DetailServicePage type="Companies" />,
                  path: ROUTES.SERVICES.SINGLE_SERVICE.COMPANIES,
                },
                {
                  element: <DetailServicePage type="Log Config" />,
                  path: ROUTES.SERVICES.SINGLE_SERVICE.LOG_CONFIG,
                },
                {
                  element: <DetailServicePage type="Logs" />,
                  path: ROUTES.SERVICES.SINGLE_SERVICE.LOGS,
                },
              ],
            },
          ],
        },
        {
          path: ROUTES.ACCESS_CONTROL.BASE,
          children: [
            {
              element: (
                <Navigate to={ROUTES.ACCESS_CONTROL.ROLES.BASE} replace />
              ),
              index: true,
            },
            {
              path: ROUTES.ACCESS_CONTROL.ROLES.BASE,
              children: [
                {
                  index: true,
                  element: <RolesPage />,
                },
                {
                  path: ROUTES.ACCESS_CONTROL.ROLES.SINGLE_ROLE.BASE,
                  children: [
                    {
                      element: (
                        <Navigate
                          to={
                            ROUTES.ACCESS_CONTROL.ROLES.SINGLE_ROLE.PERMISSIONS
                          }
                          replace
                        />
                      ),
                      index: true,
                    },
                    {
                      element: <DetailRolePage type="Permissions" />,
                      path: ROUTES.ACCESS_CONTROL.ROLES.SINGLE_ROLE.PERMISSIONS,
                    },
                    {
                      element: <DetailRolePage type="Users" />,
                      path: ROUTES.ACCESS_CONTROL.ROLES.SINGLE_ROLE.USERS,
                    },
                  ],
                },
              ],
            },
            {
              path: ROUTES.ACCESS_CONTROL.PERMISSION_SETS.BASE,
              children: [
                {
                  index: true,
                  element: <PermissionSetsPage />,
                },
                {
                  element: <PermissionSetDetailPage />,
                  path: ROUTES.ACCESS_CONTROL.PERMISSION_SETS.DETAIL,
                },
              ],
            },
          ],
        },
        {
          path: ROUTES.FILEeXPLORER.BASE,
          children: [
            {
              element: <FileExplorerPage />,
              index: true,
            },
            {
              element: <FileExplorerPage />,
              path: ROUTES.FILEeXPLORER.DETAIL,
            },
          ],
        },

        {
          path: ROUTES.LOGS.BASE,
          children: [
            {
              element: <LogsPage />,
              index: true,
            },
            {
              element: <LogDetailPage />,
              path: ROUTES.LOGS.LOG_DETAIL,
            },
          ],
        },
        {
          path: ROUTES.BLOCKCHAIN.BASE,
          children: [
            {
              element: <BlockchainManagementListPage />,
              index: true,
            },
            {
              element: <BlockchainManagementListPage />,
              path: ROUTES.BLOCKCHAIN.MANAGEMENT,
            },
            {
              path: ROUTES.BLOCKCHAIN.SINGLE_BLOCKCHAIN.BASE,
              children: [
                {
                  element: (
                    <Navigate
                      to={ROUTES.BLOCKCHAIN.SINGLE_BLOCKCHAIN.CURRENCIES}
                      replace
                    />
                  ),
                  index: true,
                },
                {
                  element: <DetailBlockchainPage type="CURRENCIES" />,
                  path: ROUTES.BLOCKCHAIN.SINGLE_BLOCKCHAIN.CURRENCIES,
                },
                {
                  element: <DetailBlockchainPage type="TRANSACTIONS" />,
                  path: ROUTES.BLOCKCHAIN.SINGLE_BLOCKCHAIN.TRANSACTIONS,
                },
              ],
            },
            {
              element: <BlockchainWalletListPage />,
              path: ROUTES.BLOCKCHAIN.WALLET,
            },
            {
              element: <WalletDetailPage />,
              path: ROUTES.BLOCKCHAIN.WALLET_DETAIL,
            },
            {
              element: <BlockchainCurrenciesListPage />,
              path: ROUTES.BLOCKCHAIN.CURRENCIES,
            },
          ],
        },
        {
          path: ROUTES.REPORTS.BASE,
          children: [
            {
              element: <ReportsPage />,
              index: true,
            },
            {
              element: <LogDetailPage />,
              path: ROUTES.REPORTS.REPORT_DETAIL,
            },
          ],
        },
        {
          path: ROUTES.DATABASE_LOGS.BASE,
          children: [
            {
              element: <DBLogsPage />,
              index: true,
            },
          ],
        },
        {
          path: ROUTES.MONITORING.BASE,
          children: [
            {
              element: <MonitoringPage />,
              index: true,
            },
          ],
        },
      ],
    },
    // No match 404
    { path: ROUTES.PAGE404, element: <NotFoundPage /> },
    { path: "*", element: <Navigate to={ROUTES.PAGE404} replace /> },
  ]);
}
