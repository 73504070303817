import { useMemo } from "react";
import { useLocales } from "src/locales";

import * as Yup from "yup";
import {
  MainType,
  ViewTypes,
  useFromGeneratorRef,
} from "@novin-npm/shared-components-front";

export const addFirebaseNotificationValidation = Yup.object({
  projectId: Yup.string().required(),
  privateKey: Yup.string().required(),
  clientEmail: Yup.string().required(),
});
export type addFirebaseNotificationType = Yup.InferType<
  typeof addFirebaseNotificationValidation
>;

export const useAddFirebaseNotificationForm = () => {
  const { translate } = useLocales();
  const formRef = useFromGeneratorRef<addFirebaseNotificationType>();

  const initialValue: addFirebaseNotificationType = {
    projectId: "",
    privateKey: "",
    clientEmail: "",
  };

  const formString = useMemo<MainType>(
    () => ({
      projectId: {
        type: ViewTypes.TextView,
        layoutProps: {
          xs: 12,
        },
        inputProps: {
          label: translate("NOTIFICATION_MANAGEMENT_PROJECTID"),
        },
      },
      privateKey: {
        type: ViewTypes.TextView,
        layoutProps: {
          xs: 12,
        },
        inputProps: {
          label: translate("NOTIFICATION_MANAGEMENT_PRIVATEKEY"),
        },
      },
      clientEmail: {
        type: ViewTypes.TextView,
        layoutProps: {
          xs: 12,
        },
        inputProps: {
          label: translate("NOTIFICATION_MANAGEMENT_CLIENT_EMAIL"),
        },
      },
    }),
    [translate]
  );
  return { formRef, formString, initialValue };
};
