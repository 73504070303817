// @mui
import { styled } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

// ----------------------------------------------------------------------
const RootStyle = styled("div")(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: "100%",
  height: "100%",
  position: "fixed",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
}));

export default function LoadingScreen({ sx, ...other }: BoxProps) {
  return (
    <RootStyle>
      <Box
        sx={{
          px: 5,
          width: 1,
          flexGrow: 1,
          minHeight: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...sx,
        }}
        {...other}
      >
        <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360 }} />
      </Box>
    </RootStyle>
  );
}
