import axios, { AxiosProgressEvent } from "axios";
import { SPACE_API } from "src/config-global";
import { CompanyCurrentControllerCompanyUsers200ResponseMeta } from "./models/sentryModel";
import { ExplorerResponseDto, FileDto } from "./models/spaceModel";

export type GeneralList<T> = {
  data: Array<T>;
  meta: CompanyCurrentControllerCompanyUsers200ResponseMeta;
};

export type GetListDto = {
  sort?: string;
  select?: string[];
  filter?: string[];
  or?: string[];
  page?: number;
  skip?: number;
  take?: number;
  excel?: string;
};

const instance = axios.create({
  baseURL: SPACE_API,
});

const downloadFile = (
  url: string,
  signal: AbortSignal,
  shouldDownloadFile: boolean = true,
  fileName?: string
) =>
  instance
    .get(url, {
      responseType: "blob",
      signal,
    })
    .then(({ data, headers }) => {
      const type = headers["content-type"];
      const blob = new Blob([data], { type });
      const downloadUrl = window.URL.createObjectURL(blob);
      if (shouldDownloadFile) {
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute(
          "download",
          fileName || headers["content-disposition"].split("=")[1]
        ); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      return { url: downloadUrl, type };
    });

export default {
  instance,
  downloadFile,
  getFolderContent: ({
    params,
    folderId = "",
  }: {
    params: GetListDto;
    folderId?: string;
  }) => {
    const queryString = folderId ? `?id=${folderId}` : "";
    return instance
      .get<ExplorerResponseDto>(`folders/explorer${queryString}`, { params })
      .then((res) => res.data);
  },
  getFolderStatics: () =>
    instance.get("folders/statics").then((res) => res.data),
  uploadImage: (
    file: File,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
    controller: AbortController
  ) => {
    const formData = new FormData();
    formData.append("files", file);

    const config = {
      onUploadProgress,
      headers: { "content-type": "multipart/form-data" },
      signal: controller.signal,
    };
    return instance
      .post<FileDto[]>("files/upload", formData, config)
      .then((res) => res.data);
  },
};
