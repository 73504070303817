import { useQuery } from "@tanstack/react-query";
import notifAxios, { GetListDto } from "src/axios/notifAxios";
import { QUERY_KEYS } from "../../../constants";

export const useGetAllEmailHistoryNotification = (tableState?: GetListDto) =>
  useQuery({
    queryKey: [
      QUERY_KEYS.NOTIFICATION.NOTIFICATION_EMAIL_HISTORY_LIST,
      tableState,
    ],
    queryFn: () => notifAxios.getAllEmailHistoryNotification(tableState),
    enabled: tableState && !!Object.keys(tableState).length,
  });
