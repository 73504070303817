import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import axios, { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useMemo, useCallback } from "react";
import { useLocales } from "src/locales";
import { handleArrayErrorMessages } from "src/utils/utils";

type CustomQueryClientProviderPropsType = {
  children: JSX.Element[] | JSX.Element;
};

export const CustomQueryClientProvider = ({
  children,
}: CustomQueryClientProviderPropsType) => {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();

  const handle422Errors = useCallback(
    (error: AxiosError) => {
      const newErrorMsgArray = handleArrayErrorMessages(
        (error.response?.data as any)?.message || error.message
      );
      newErrorMsgArray.forEach((newErrorMsg) => {
        const errorMessageKey = `SERVER_ERRORS.ARRAY_OF_ERRORS.${newErrorMsg.reason}`;

        const translatedMessageCause = translate(errorMessageKey);

        const finalErrorMsg = `${newErrorMsg?.cause}: ${translatedMessageCause} ${newErrorMsg?.additionalReson} ${
          newErrorMsg?.indexOfNested
            ? `${translate("IN_INDEX_OF")} ${newErrorMsg?.indexOfNested}`
            : ""
        }`;

        enqueueSnackbar(finalErrorMsg, {
          variant: "error",
        });
      });
    },
    [enqueueSnackbar, translate]
  );

  const queryClient = useMemo(() => {
    const errorHandler = (error: unknown) => {
      if (axios.isAxiosError(error) && error?.response?.status === 422) {
        handle422Errors(error);
      } else {
        const messageKey = `SERVER_ERRORS.${
          axios.isAxiosError(error)
            ? error.response?.data?.message
            : error instanceof Error
              ? error.message
              : error
        }`;
        const translatedMessage = translate(messageKey);

        enqueueSnackbar(
          messageKey === translatedMessage
            ? translate("somethingWentWrong")
            : translatedMessage,
          {
            variant: "error",
          }
        );
      }
    };

    return new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          staleTime: 30000,
          retry: 2,
        },
        mutations: {
          onError: errorHandler,
        },
      },
    });
  }, [enqueueSnackbar, handle422Errors, translate]);

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
