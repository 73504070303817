import { useQuery } from "@tanstack/react-query";
import notifAxios, { GetListDto } from "src/axios/notifAxios";
import { QUERY_KEYS } from "../../../constants";

export const useGetAllMessageHistoryNotification = ({
  tableState,
  isEnabled,
}: {
  tableState?: GetListDto;
  isEnabled?: boolean;
}) =>
  useQuery({
    queryKey: [
      QUERY_KEYS.NOTIFICATION.NOTIFICATION_MESSAGE_HISTORY_LIST,
      tableState,
    ],
    queryFn: () => notifAxios.getAllMessageHistoryNotification(tableState),
    enabled: tableState && !!Object.values(tableState).length,
  });
