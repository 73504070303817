import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "src/constants";
import { useLocales } from "src/locales";
import notifAxios from "src/axios/notifAxios";
import { UpdateSettingDto } from "src/axios/models/notifModel";

export const useUpdateNotification = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: ({
      notificationId,
      data,
    }: {
      notificationId: string;
      data: UpdateSettingDto;
    }) => notifAxios.updateNotification(notificationId, data),

    // Defining an 'onSuccess' callback function which is executed when data fetching is successful.
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.NOTIFICATION.NOTIFICATION_LIST],
      });

      enqueueSnackbar(
        translate("NOTIFICATION_MANAGEMENT_UPDATED_SUCCESSFULLY")
      );
      if (onSuccess) {
        onSuccess();
      }
    },

    // Executed if the mutation encounters an error.
    onError: (error: any) => {
      enqueueSnackbar(translate(error?.response?.data?.message), {
        variant: "error",
      });
    },
  });

  return { updateNotification: mutate, pending: isPending };
};
