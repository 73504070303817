import { Dispatch, SetStateAction } from "react";
import { Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { GeneralDialogState } from "src/components/dialog/SimpleDialog";
import { EmailHistoryDto } from "src/axios/models/notifModel";
import { useLocales } from "src/locales";

type mailDetailProps = {
  mailDetailDialog: GeneralDialogState<EmailHistoryDto>;
  setMailDetailDialog: Dispatch<
    SetStateAction<GeneralDialogState<EmailHistoryDto>>
  >;
};

const MailNotificationHistoryDetailDialog = ({
  mailDetailDialog,
  setMailDetailDialog,
}: mailDetailProps) => {
  const { translate } = useLocales();
  const decodeHTMLEntities = (str: string): string => {
    const txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value;
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={mailDetailDialog.show}
      onClose={() =>
        setMailDetailDialog((perv) => ({
          show: false,
          data: {} as EmailHistoryDto,
        }))
      }
    >
      <DialogTitle>{translate("NOTIFICATION_HISTORY_MAIL_DETAIL")}</DialogTitle>
      <DialogContent sx={{ py: 5 }}>
        <Stack direction="column" spacing={2}>
          <TextField
            disabled
            label={translate("NOTIFICATION_MANAGEMENT_SENDER")}
            value={
              mailDetailDialog?.data?.createdByUserCompany?.user?.fullName || ""
            }
          />
          <TextField
            disabled
            label={translate("NOTIFICATION_HISTORY_RECIPIENT")}
            value={mailDetailDialog?.data?.recipient || ""}
          />
          <TextField
            disabled
            label={translate("NOTIFICATION_HISTORY_TEXT")}
            value={mailDetailDialog?.data?.text || ""}
          />
          <TextField
            disabled
            label="html"
            multiline
            rows={4}
            value={decodeHTMLEntities(mailDetailDialog?.data?.html)}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default MailNotificationHistoryDetailDialog;
