import {
  DialogProps,
  Button,
  Divider,
  Box,
  ButtonProps,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { SetStateAction, Dispatch } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { ObjectType } from "src/types/common";
import { useLocales } from "src/locales";

export type GeneralDialogState<T = ObjectType> = {
  show: boolean;
  data: T;
};

type SimpleDialogProps<T = {}> = {
  dialog: GeneralDialogState<T>;
  setDialog: Dispatch<SetStateAction<GeneralDialogState<T>>>;
  children: JSX.Element | string;
  onSubmit: () => void;
  title: string;
  loading: boolean;
  hasFooter?: boolean;
  thirdBtnRenderer?: JSX.Element;
  saveBtnText?: string;
  saveBtnColor?: ButtonProps["color"];
};

export function SimpleDialog<T = {}>(
  props: SimpleDialogProps<T> & Omit<DialogProps, "open">
) {
  const {
    dialog,
    setDialog,
    children,
    onSubmit,
    title,
    loading,
    hasFooter = true,
    thirdBtnRenderer,
    saveBtnText,
    saveBtnColor,
    ...restProps
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { translate } = useLocales();

  if (dialog.show) {
    return (
      <Dialog
        open
        onClose={() => {
          if (!loading) {
            setDialog((pre: any) => ({ ...pre, show: false }));
          }
        }}
        fullWidth
        fullScreen={fullScreen}
        maxWidth="md"
        {...restProps}
      >
        <DialogTitle mb={1}>
          <Typography variant="h5">{title}</Typography>
        </DialogTitle>
        <Box sx={{ position: "relative" }}>
          <DialogContent>{children}</DialogContent>
        </Box>
        {hasFooter && (
          <>
            <Divider variant="fullWidth" />
            <DialogActions>
              <Button
                disabled={loading}
                onClick={() => {
                  setDialog((pre) => ({ ...pre, show: false }));
                }}
                variant="outlined"
              >
                {translate("CANCEL")}
              </Button>
              {thirdBtnRenderer}
              <Button
                data-test-id="save-btn"
                disabled={loading}
                autoFocus
                color={saveBtnColor}
                variant="contained"
                onClick={() => {
                  onSubmit();
                }}
              >
                {loading
                  ? translate("LOADING")
                  : saveBtnText || translate("SAVE")}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
  return null;
}
