// @mui
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
//
import { useNavigate } from "react-router";
import { useLocales } from "src/locales";
import { CustomBreadcrumbsProps } from "./types";
import LinkItem from "./link-item";

// ----------------------------------------------------------------------

export default function CustomBreadcrumbs({
  links,
  action,
  heading,
  moreLink,
  activeLast,
  backButton,
  sx,
  ...other
}: CustomBreadcrumbsProps) {
  const lastLink = !!links.length ? links[links.length - 1].name : 0;
  const navigate = useNavigate();
  const { translate, currentLang } = useLocales();

  return (
    <Box sx={{ ...sx }}>
      {backButton && (
        <Box sx={{ my: 5 }}>
          <Button
            variant="outlined"
            onClick={() => navigate(-1)}
            startIcon={
              currentLang.value === "en" ? (
                <ArrowBackIcon />
              ) : (
                <ArrowForwardIcon />
              )
            }
          >
            {translate("BACK")}
          </Button>
        </Box>
      )}
      <Stack direction="row" alignItems="center">
        <Box sx={{ flexGrow: 1 }}>
          {/* HEADING */}
          {heading && (
            <Typography variant="h4" gutterBottom>
              {heading}
            </Typography>
          )}

          {/* BREADCRUMBS */}
          {!!links.length && (
            <Breadcrumbs separator={<Separator />} {...other}>
              {links.map((link) => (
                <LinkItem
                  key={link.name || ""}
                  link={link}
                  activeLast={activeLast}
                  disabled={link.name === lastLink}
                />
              ))}
            </Breadcrumbs>
          )}
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}> {action} </Box>}
      </Stack>

      {/* MORE LINK */}
      {!!moreLink && (
        <Box sx={{ mt: 2 }}>
          {moreLink.map((href) => (
            <Link
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: "table" }}
            >
              {href}
            </Link>
          ))}
        </Box>
      )}
    </Box>
  );
}

// ----------------------------------------------------------------------

function Separator() {
  return (
    <Box
      component="span"
      sx={{
        width: 4,
        height: 4,
        borderRadius: "50%",
        bgcolor: "text.disabled",
      }}
    />
  );
}
