import { useState } from "react";
import Page from "src/components/page/page";
import { useLocales } from "src/locales";
import { CustomDataGrid } from "src/components/customComponents/dataGrid/CustomDataGrid";
import {
  QueryType,
  useDataGridRef,
  FetchingDataEnum,
} from "@novin-npm/shared-components-front";
import { tableStateToArray } from "src/utils/utils";
import { useGetAllEmailHistoryNotification } from "src/hooks/query&mutation/Notification/useGetAllEmailHistoryNotification";
import moment from "moment";
import { GeneralDialogState } from "src/components/dialog/SimpleDialog";

import { EmailHistoryDto } from "src/axios/models/notifModel";

import MailNotificationHistoryDetailDialog from "./dialog/MailNotificationHistoryDetailDialog";

function MailNotificationHistory() {
  const { translate, currentLang } = useLocales();
  const tableRef = useDataGridRef();
  const [mailDetailDialog, setMailDetailDialog] = useState<
    GeneralDialogState<EmailHistoryDto>
  >({
    show: false,
    data: {} as EmailHistoryDto,
  });
  const [tableState, setTableState] = useState<QueryType>();

  const { data, isLoading, isError, isFetching, refetch } =
    useGetAllEmailHistoryNotification(tableStateToArray(tableState));

  return (
    <Page title={translate("NOTIFICATION_HISTORY_MAIL_NOTIFICATION")}>
      <CustomDataGrid
        tableRef={tableRef}
        columns={[
          {
            field: "subject",
            type: "string",
            headerName: translate("NOTIFICATION_HISTORY_SUBJECT"),
            flex: 1,
            minWidth: 100,
          },
          {
            field: "text",
            type: "string",
            headerAlign: "left",
            align: "left",
            headerName: translate("NOTIFICATION_HISTORY_TEXT"),
            flex: 1,
            minWidth: 100,
          },
          {
            field: "html",
            type: "string",
            headerAlign: "left",
            align: "left",
            headerName: translate("html"),
            flex: 1,
            minWidth: 100,
          },
          {
            field: "createdByUserCompany.user.fullName",
            type: "string",
            headerName: translate("NOTIFICATION_HISTORY_CREATEDBY"),
            flex: 1,
            minWidth: 100,
          },
          {
            field: "recipient",
            type: "string",
            headerName: translate("NOTIFICATION_HISTORY_RECIPIENT"),
            flex: 1,
            minWidth: 100,
          },
          {
            field: "createdByUserCompany.company.name",
            type: "string",
            headerAlign: "left",
            align: "left",
            headerName: translate("NOTIFICATION_HISTORY_SENDER_COMPANY"),
            flex: 1,
            minWidth: 100,
          },
          {
            field: "updatedAt",
            type: "date",
            headerName: translate("SERVICE_UPDATE_AT"),
            flex: 1,
            minWidth: 100,
            valueGetter: (params) => new Date(params.value),
            valueFormatter: ({ value }) =>
              moment(value)
                .locale(currentLang.value)
                .format("YYYY/MM/DD , HH:MM"),
          },
          {
            field: "createdAt",
            type: "date",
            headerName: translate("SERVICE_CREATED_AT"),
            flex: 1,
            minWidth: 100,
            valueGetter: (params) => new Date(params.value),
            valueFormatter: ({ value }) =>
              moment(value)
                .locale(currentLang.value)
                .format("YYYY/MM/DD , HH:MM"),
          },
        ]}
        isFetching={isFetching}
        dataFetching={{
          type: FetchingDataEnum.MANUAL,
          loading: isLoading,
          error: isError,
          onRetry: refetch,
          data: {
            items: data?.data || [],
            rowCount: data?.meta?.total || 0,
          },
        }}
        onRowClick={(params, event) => {
          setMailDetailDialog({
            show: true,
            data: params?.row || {},
          });
        }}
        disableRowSelectionOnClick={true}
        onQueryUpdate={(query) => {
          setTableState(query);
        }}
        pageSizeOptions={[10, 20, 50, 100]}
        slotProps={{
          row: {
            style: {
              cursor: "pointer",
            },
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
      {mailDetailDialog.show && (
        <MailNotificationHistoryDetailDialog
          mailDetailDialog={mailDetailDialog}
          setMailDetailDialog={setMailDetailDialog}
        />
      )}
    </Page>
  );
}

export default MailNotificationHistory;
