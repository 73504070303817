// i18n
import "src/locales/i18n";
import Keycloak from "keycloak-js";
import { ReactKeycloakProvider } from "@react-keycloak/web";

// scrollbar
import "simplebar-react/dist/simplebar.min.css";

// lightbox
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

// image
import "react-lazy-load-image-component/src/effects/blur.css";

// ----------------------------------------------------------------------

// @mui
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// theme
import ThemeProvider from "src/theme";
// hooks
import { useScrollToTop } from "src/hooks/use-scroll-to-top";
// components
import ProgressBar from "src/components/progress-bar";
import MotionLazy from "src/components/animate/motion-lazy";
import SnackbarProvider from "src/components/snackbar/snackbar-provider";
import { SettingsProvider } from "src/components/settings";
import Router from "./routes";
import { fix_axios_token } from "./utils/utils";
import { CustomQueryClientProvider } from "./context/CustomQueryClientProvider";
import { PermissionProvider } from "./context/PermissionProvider";

// ----------------------------------------------------------------------
const keycloak = new Keycloak({
  realm: import.meta.env.VITE_KEYCLOAK_REALM!,
  url: import.meta.env.VITE_KEYCLOAK_URL,
  clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID!,
});
const initOptions = {
  onLoad: "check-sso",
  silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
  pkceMethod: "S256",
};

export default function App() {
  useScrollToTop();

  return (
    <ReactKeycloakProvider
      initOptions={initOptions}
      authClient={keycloak}
      onEvent={(event, error) => {
        fix_axios_token(`bearer ${keycloak.token}`);
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider
          defaultSettings={{
            themeMode: "light", // 'light' | 'dark'
            themeDirection: "ltr", //  'rtl' | 'ltr'
            themeContrast: "default", // 'default' | 'bold'
            themeLayout: "vertical", // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: "default", // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <CustomQueryClientProvider>
            <PermissionProvider>
              <ThemeProvider>
                <MotionLazy>
                  <SnackbarProvider>
                    <ProgressBar />
                    <Router />
                  </SnackbarProvider>
                </MotionLazy>
              </ThemeProvider>
            </PermissionProvider>
          </CustomQueryClientProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </ReactKeycloakProvider>
  );
}
