import { Dispatch, SetStateAction, useState, useRef } from "react";

import { useLocales } from "src/locales";
import { CustomFormGenerator } from "src/components/customComponents/formGenerator/CustomFormGenerator";
import {
  GeneralDialogState,
  SimpleDialog,
} from "src/components/dialog/SimpleDialog";
import { useAddNotification } from "src/hooks/query&mutation/Notification/useAddNotification";
import {
  useAddMailSettingNotificationForm,
  addMailSettingNotificationType,
  addMailSettingNotificationValidation,
} from "src/hooks/form-string/Notification/useAddMailSettingNotificationForm";
import {
  useAddFirebaseNotificationForm,
  addFirebaseNotificationType,
  addFirebaseNotificationValidation,
} from "src/hooks/form-string/Notification/useAddFirebaseNotificationForm";
import {
  useAddSmsKavenegarNotificationForm,
  addSmsKavenegarNotificationType,
  addSmsKavenegarNotificationValidation,
} from "src/hooks/form-string/Notification/useAddSmsKavenegarNotificationForm";
import {
  useAddWebPushNotificationForm,
  addWebPushNotificationType,
  addWebPushNotificationValidation,
} from "src/hooks/form-string/Notification/useAddWebPushNotificationForm";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Switch,
  Stack,
} from "@mui/material";
import {
  CreateSettingDto,
  CreateSettingDtoKeyEnum,
  SettingDto,
  SettingDtoKeyEnum,
} from "src/axios/models/notifModel";
import { useUpdateNotification } from "src/hooks/query&mutation/Notification/useUpdateNotification";
import { capitalize } from "src/utils/utils";
import { useGetAllNotification } from "src/hooks/query&mutation/Notification/useGetAllNotification";
import { useSnackbar } from "notistack";
type addNotificationDialogProps = {
  addNotificationDialog: GeneralDialogState<SettingDto>;
  setAddNotificationDialog: Dispatch<
    SetStateAction<GeneralDialogState<SettingDto>>
  >;
};

const CreateNewNotificationDialog = ({
  addNotificationDialog,
  setAddNotificationDialog,
}: addNotificationDialogProps) => {
  const initialSettingValue: SettingDto | CreateSettingDto = {
    key: SettingDtoKeyEnum.MAIL || CreateSettingDtoKeyEnum.MAIL,
    value: {},
    isActive: false,
    id: "",
    createdAt: new Date(),
  };
  const [selectedNotificationType, setSelectedNotificationType] = useState(
    String(
      addNotificationDialog?.data?.key
        ? addNotificationDialog?.data?.key
        : CreateSettingDtoKeyEnum.MAIL
    )
  );
  const [isNotificationActive, setIsNotificationActive] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const { data: allNotificationList } = useGetAllNotification({
    tableState: { take: 1000 },
  });
  const { createNotification, pending } = useAddNotification({
    onSuccess: () => {
      setAddNotificationDialog({
        show: false,
        data: initialSettingValue,
      });
    },
  });
  const { updateNotification } = useUpdateNotification({
    onSuccess: () => {
      setAddNotificationDialog({
        show: false,
        data: initialSettingValue,
      });
    },
  });
  const { formRef: mailFormRef, formString: mailformString } =
    useAddMailSettingNotificationForm();
  const { formRef: FirebaseFormRef, formString: FirebaseformString } =
    useAddFirebaseNotificationForm();
  const { formRef: smsFormRef, formString: smsformString } =
    useAddSmsKavenegarNotificationForm();
  const { formRef: webPushFormRef, formString: webPushformString } =
    useAddWebPushNotificationForm();
  const isNotificationActiveRef = useRef(false);

  return (
    <SimpleDialog
      loading={pending}
      dialog={addNotificationDialog}
      setDialog={setAddNotificationDialog}
      title={translate("NOTIFICATION_MANAGEMENT_NEW_NOTIFICATION")}
      onSubmit={() => {
        if (selectedNotificationType === String(CreateSettingDtoKeyEnum.MAIL)) {
          mailFormRef.current?.callSubmit();
        } else if (
          selectedNotificationType ===
          String(CreateSettingDtoKeyEnum.SMS_KAVENEGAR)
        ) {
          smsFormRef.current?.callSubmit();
        } else if (
          selectedNotificationType === String(CreateSettingDtoKeyEnum.FIREBASE)
        ) {
          FirebaseFormRef.current?.callSubmit();
        } else if (
          selectedNotificationType === String(CreateSettingDtoKeyEnum.WEB_PUSH)
        ) {
          webPushFormRef.current?.callSubmit();
        }
      }}
      PaperProps={{
        id: "addNotificationDialog",
      }}
    >
      <Stack spacing={2} pt={2} pb={2}>
        <FormControl fullWidth>
          <InputLabel id="notification-type-label">
            {translate("NOTIFICATION_MANAGEMENT_NOTIFICATION_TYPE")}
          </InputLabel>

          <Select
            labelId="notification-type-label"
            id="notification-select-type"
            label="NotificationType"
            data-test-id="notification-select-type"
            disabled={!!addNotificationDialog?.data?.id}
            value={selectedNotificationType}
            onChange={({ target }) => {
              setSelectedNotificationType(target.value);
            }}
          >
            {Object.keys(CreateSettingDtoKeyEnum)?.map((item) => (
              <MenuItem
                data-test-id={`notification-select-${item}`}
                key={item}
                value={item}
              >
                {capitalize(item?.split("_").join(" "))}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* show selected Notification type */}
        {selectedNotificationType === String(CreateSettingDtoKeyEnum.MAIL) && (
          <Box>
            <CustomFormGenerator<addMailSettingNotificationType>
              loading={false}
              formString={mailformString}
              initialValues={{
                host: "" || addNotificationDialog?.data?.value?.host,
                username: "" || addNotificationDialog?.data?.value?.username,
                password: "" || addNotificationDialog?.data?.value?.password,
                sender: "" || addNotificationDialog?.data?.value?.sender,
                port: 0 || addNotificationDialog?.data?.value?.port,
              }}
              validationSchema={addMailSettingNotificationValidation}
              formRef={mailFormRef}
              onSubmit={(values) => {
                if (addNotificationDialog?.data?.id) {
                  updateNotification({
                    notificationId: addNotificationDialog?.data?.id,
                    data: {
                      value: { ...values, port: Number(values.port) },
                      isActive: isNotificationActiveRef.current,
                    },
                  });
                } else
                  createNotification({
                    key: CreateSettingDtoKeyEnum.MAIL,
                    value: { ...values, port: Number(values.port) },
                    isActive: isNotificationActiveRef.current,
                  });
              }}
            />
          </Box>
        )}
        {selectedNotificationType ===
          String(CreateSettingDtoKeyEnum.SMS_KAVENEGAR) && (
          <Box>
            <CustomFormGenerator<addSmsKavenegarNotificationType>
              loading={false}
              formString={smsformString}
              initialValues={{
                baseUrl: "" || addNotificationDialog?.data?.value?.baseUrl,
                token: "" || addNotificationDialog?.data?.value?.token,
              }}
              validationSchema={addSmsKavenegarNotificationValidation}
              formRef={smsFormRef}
              onSubmit={(values) => {
                if (addNotificationDialog?.data?.id) {
                  updateNotification({
                    notificationId: addNotificationDialog?.data?.id,
                    data: {
                      value: { ...values },
                      isActive: isNotificationActiveRef.current,
                    },
                  });
                } else
                  createNotification({
                    key: CreateSettingDtoKeyEnum.SMS_KAVENEGAR,
                    value: { ...values },
                    isActive: isNotificationActiveRef.current,
                  });
              }}
            />
          </Box>
        )}
        {selectedNotificationType ===
          String(CreateSettingDtoKeyEnum.FIREBASE) && (
          <Box>
            <CustomFormGenerator<addFirebaseNotificationType>
              loading={false}
              formString={FirebaseformString}
              initialValues={{
                projectId: "" || addNotificationDialog?.data?.value?.projectId,
                privateKey:
                  "" || addNotificationDialog?.data?.value?.privateKey,
                clientEmail:
                  "" || addNotificationDialog?.data?.value?.clientEmail,
              }}
              validationSchema={addFirebaseNotificationValidation}
              formRef={FirebaseFormRef}
              onSubmit={(values) => {
                if (addNotificationDialog?.data?.id) {
                  updateNotification({
                    notificationId: addNotificationDialog?.data?.id,
                    data: {
                      value: { ...values },
                      isActive: isNotificationActiveRef.current,
                    },
                  });
                } else
                  createNotification({
                    key: CreateSettingDtoKeyEnum.FIREBASE,
                    value: { ...values },
                    isActive: isNotificationActiveRef.current,
                  });
              }}
            />
          </Box>
        )}
        {selectedNotificationType ===
          String(CreateSettingDtoKeyEnum.WEB_PUSH) && (
          <Box>
            <CustomFormGenerator<addWebPushNotificationType>
              loading={false}
              formString={webPushformString}
              initialValues={{
                vapidSubject:
                  "" || addNotificationDialog?.data?.value?.vapidSubject,
                vapidPublicKey:
                  "" || addNotificationDialog?.data?.value?.vapidPublicKey,
                vapidPrivateKey:
                  "" || addNotificationDialog?.data?.value?.vapidPrivateKey,
              }}
              validationSchema={addWebPushNotificationValidation}
              formRef={webPushFormRef}
              onSubmit={(values) => {
                if (addNotificationDialog?.data?.id) {
                  updateNotification({
                    notificationId: addNotificationDialog?.data?.id,
                    data: {
                      value: { ...values },
                      isActive: isNotificationActiveRef.current,
                    },
                  });
                } else
                  createNotification({
                    key: CreateSettingDtoKeyEnum.WEB_PUSH,
                    value: { ...values },
                    isActive: isNotificationActiveRef.current,
                  });
              }}
            />
          </Box>
        )}
        <FormControlLabel
          control={
            <Switch
              data-test-id={`notification-management-activation`}
              checked={
                isNotificationActive || addNotificationDialog?.data?.isActive
              }
              value={
                isNotificationActive || addNotificationDialog?.data?.isActive
              }
              onChange={(event) => {
                let filteredNotificationData =
                  allNotificationList?.data.find(
                    (noti) =>
                      noti?.key === selectedNotificationType &&
                      noti?.isActive === true
                  ) || {};

                if (
                  event.target.checked === true &&
                  Object.values(filteredNotificationData).length
                ) {
                  enqueueSnackbar(
                    translate(
                      "NOTIFICATION_HISTORY_AlREADY_HAD_ACTIVE_NOTIFICATION"
                    )
                  );
                } else {
                  isNotificationActiveRef.current = event.target.checked;
                  setIsNotificationActive(event.target.checked);
                }
              }}
            />
          }
          label={translate("NOTIFICATION_MANAGEMENT_IS_ACTIVE")}
          labelPlacement="end"
        />
      </Stack>
    </SimpleDialog>
  );
};

export default CreateNewNotificationDialog;
