import { useState } from "react";
import {
  DataGrid,
  DataGridPropsType,
  DataGridPro,
  Language,
} from "@novin-npm/shared-components-front";
import { useNavigate } from "react-router";
import { useLocales } from "src/locales";

export const CustomDataGrid = (props: DataGridPropsType) => {
  const { currentLang } = useLocales();
  const navigation = useNavigate();
  // note: empty object means all columns are visible at first
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<DataGridPro.GridColumnVisibilityModel>({});

  return (
    <DataGrid
      navigation={navigation}
      lang={currentLang.value as Language}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={(model) => setColumnVisibilityModel(model)}
      {...props}
      disableMultipleColumnsSorting={false}
    />
  );
};
