import { useQuery } from "@tanstack/react-query";
import sentryAxios from "src/axios/sentryAxios";
import { QUERY_KEYS } from "../../../constants";

export const useMyProfile = (enabled?: boolean) =>
  useQuery({
    queryKey: [QUERY_KEYS.MY_PROFILE],
    queryFn: sentryAxios.getMyProfile,
    refetchOnMount: true,
    enabled: enabled,
  });
