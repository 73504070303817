import { Box, Card, Tab, Tabs, Container } from "@mui/material";
import { useLocales } from "src/locales";
import { useNavigate } from "react-router";
import useTabs from "src/hooks/useTabs";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import { ABSOLUTE_ROUTES } from "src/routes/paths";
import Page from "src/components/page/page";
import { capitalize } from "src/utils/utils";
import { useSettingsContext } from "src/components/settings";
import SmsNotificationHistory from "./NotificationhistoryTable/SmsNotificationHistory";
import MailNotificationHistory from "./NotificationhistoryTable/MailNotificationHistory";
import MessageNotificationHistory from "./NotificationhistoryTable/MessageNotificationHistory";

type historyNotificationTab = "SMS" | "MAIL" | "MESSAGE";
interface HistoryNotificationTabProps {
  type: historyNotificationTab;
}
export const HistoryNotification = ({ type }: HistoryNotificationTabProps) => {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const { themeStretch } = useSettingsContext();
  const { currentTab, onChangeTab } = useTabs(type);
  const Tab_List = [
    {
      value: "SMS",
      component: <SmsNotificationHistory />,
      path: ABSOLUTE_ROUTES.NOTIFICATION.HISTORY.SMS,
    },
    {
      value: "MAIL",
      component: <MailNotificationHistory />,
      path: ABSOLUTE_ROUTES.NOTIFICATION.HISTORY.MAIL,
    },
    {
      value: "MESSAGE",
      component: <MessageNotificationHistory />,
      path: ABSOLUTE_ROUTES.NOTIFICATION.HISTORY.MESSAGE,
    },
  ];
  return (
    <Page title={translate("NOTIFICATION_HISTORY")}>
      <Container
        maxWidth={themeStretch ? false : "xl"}
        sx={{ height: "100%", display: "flex", flexDirection: "column", my: 4 }}
      >
        <CustomBreadcrumbs
          heading={translate("NOTIFICATION_HISTORY")}
          links={[
            {
              name: translate("MENU_DASHBOARD"),
              href: ABSOLUTE_ROUTES.BASE,
            },
            {
              name: translate("NOTIFICATION_HISTORY"),
            },
          ]}
          sx={{ mb: 5 }}
        />
        <Card>
          <Tabs
            data-test-id="historyNotification"
            sx={{ paddingRight: 1, paddingLeft: 1 }}
            value={currentTab}
            onChange={onChangeTab}
          >
            {Tab_List.map((tab) => (
              <Tab
                data-test-id={`history-notification-${tab.value}-tab`}
                key={tab.value}
                value={tab.value}
                label={capitalize(tab.value)}
                onClick={() => navigate(tab.path)}
              />
            ))}
          </Tabs>
          {Tab_List.map((tab) => {
            const isMatched = tab.value === currentTab;
            return (
              isMatched && (
                <Box
                  data-test-id={`history-notification-${tab.value}`}
                  key={tab.value}
                >
                  {tab.component}
                </Box>
              )
            );
          })}
        </Card>
      </Container>
    </Page>
  );
};
