import { useMemo } from "react";
import { useLocales } from "src/locales";

import * as Yup from "yup";
import {
  MainType,
  ViewTypes,
  useFromGeneratorRef,
} from "@novin-npm/shared-components-front";

export const addSmsKavenegarNotificationValidation = Yup.object({
  baseUrl: Yup.string().required(),
  token: Yup.string().required(),
});
export type addSmsKavenegarNotificationType = Yup.InferType<
  typeof addSmsKavenegarNotificationValidation
>;

export const useAddSmsKavenegarNotificationForm = () => {
  const { translate } = useLocales();
  const formRef = useFromGeneratorRef<addSmsKavenegarNotificationType>();

  const initialValue: addSmsKavenegarNotificationType = {
    baseUrl: "",
    token: "",
  };

  const formString = useMemo<MainType>(
    () => ({
      baseUrl: {
        type: ViewTypes.TextView,
        layoutProps: {
          xs: 12,
        },
        inputProps: {
          label: translate("NOTIFICATION_MANAGEMENT_BASE_URL"),
        },
      },
      token: {
        type: ViewTypes.TextView,
        layoutProps: {
          xs: 12,
        },
        inputProps: {
          label: translate("NOTIFICATION_MANAGEMENT_TOKEN"),
        },
      },
    }),
    [translate]
  );
  return { formRef, formString, initialValue };
};
