import React from "react";
import { Skeleton } from "@mui/material";
import { SxProps } from "@mui/material/styles";
import { useQuery } from "@tanstack/react-query";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { stringAvatar } from "src/utils/utils";
import Image from "../image";

type GetFileLocalPathType = { url: string; fileName: string };

// Custom hook to fetch file local path using react-query and handle authorization with Keycloak.

const useGetFileLocalPath = ({ url, fileName }: GetFileLocalPathType) => {
  const { keycloak } = useKeycloak();

  return useQuery({
    queryKey: ["IMAGES", url],
    queryFn: () =>
      axios
        .get<Buffer>(url, {
          responseType: "arraybuffer",
          headers: {
            Authorization: `bearer ${keycloak.token}`,
          },
        })
        .then((res) => {
          const array = new Uint8Array(res.data);
          let blobToFile: any = new Blob([array]);
          blobToFile.lastModifiedDate = new Date();
          blobToFile.name = fileName;
          if (blobToFile) {
            return URL.createObjectURL(blobToFile);
          }
          return null;
        }),
    refetchOnMount: false,
    staleTime: Infinity,
    enabled: !!fileName && !url.includes("undefined") && !url.includes("null"),
  });
};

const getPlaceholderImage = (type: CustomAvatarProps["type"]) => {
  switch (type) {
    case "image":
      return "/assets/null_image.png";
    case "avatar":
      return "/assets/null_avatar.png";
    case "avatar_letter":
      return "";
    case "currency":
      return "/assets/null_currency.png";
  }
};
const getSkeletonType = (shape: CustomAvatarProps["shape"]) => {
  switch (shape) {
    case "circular":
      return "circular";
    case "rounded":
      return "rounded";
    case "square":
      return "rectangular";
  }
};

type CustomAvatarProps = {
  shape?: "circular" | "rounded" | "square";
  type?: "image" | "avatar" | "avatar_letter" | "currency";
  url: string;
  fileName?: string;
  sx?: SxProps;
};
export const CustomAvatar = (props: CustomAvatarProps) => {
  const { type = "avatar", shape = "circular" } = props;
  const { isLoading, data } = useGetFileLocalPath({
    url: props.url,
    fileName: props.fileName || props.url,
  });
  return isLoading ? (
    <Skeleton variant={getSkeletonType(shape)} sx={{ ...props.sx }}>
      <Avatar variant={shape} sx={props.sx} />
    </Skeleton>
  ) : (
    <Tooltip title={props.fileName}>
      <Avatar variant={shape} sx={{ width: 32, height: 32, ...props.sx }}>
        {props.type === "avatar_letter" && !data ? (
          <Avatar {...stringAvatar(props.fileName || "")} />
        ) : (
          <Image
            height="100%"
            src={data || getPlaceholderImage(type)}
            alt={props.fileName || props.url}
          />
        )}
      </Avatar>
    </Tooltip>
  );
};
