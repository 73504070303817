export const SENTRY_API = import.meta.env.VITE_SENTRY_API;
export const LOG_API = import.meta.env.VITE_LOG_URL;
export const NOTIF_API = import.meta.env.VITE_NOTIF_URL;
export const SPACE_API = import.meta.env.VITE_SPACE_URL;
export const MAGMA_CLIENT_HOST = import.meta.env.VITE_MAGMA_CLIENT;
export const MAGMA_ADMIN_HOST = import.meta.env.VITE_MAGMA_ADMIN;
export const RABBITMQ_HOST = import.meta.env.VITE_RABBITMQ;
export const KEYCLOAK_HOST = import.meta.env.VITE_KEYCLOAK;
export const BLOCKCHAIN_API = import.meta.env.VITE_BLOCKCHAIN_URL;
export const VITE_BASE_URL = import.meta.env.VITE_BASE;
