import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "src/constants";
import { useLocales } from "src/locales";
import notifAxios from "src/axios/notifAxios";
import { CreateSettingDto } from "src/axios/models/notifModel";

export const useAddNotification = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: (data: CreateSettingDto) => notifAxios.addNewNotification(data),

    // Defining an 'onSuccess' callback function which is executed when data fetching is successful.
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.NOTIFICATION.NOTIFICATION_LIST],
      });

      enqueueSnackbar(
        translate("NOTIFICATION_MANAGEMENT_CREATE_NOTIFICATION_SUCCESSFULLY")
      );
      if (onSuccess) {
        onSuccess();
      }
    },

    // Executed if the mutation encounters an error.
    onError: (error: any) => {
      enqueueSnackbar(translate(error?.response?.data?.message), {
        variant: "error",
      });
    },
  });

  return { createNotification: mutate, pending: isPending };
};
