import { useCallback } from "react";
import { FormikValues } from "formik";
import {
  FormGenerator,
  FormGeneratorPropsType,
  Language,
} from "@novin-npm/shared-components-front";
import { useLocales } from "src/locales";
import { ObjectType } from "src/types/common";

export const CustomFormGenerator = <T extends FormikValues>(
  props: FormGeneratorPropsType<T>
) => {
  const { currentLang, translate } = useLocales();

  const translatorFn = useCallback(
    (item: string | ObjectType) => {
      if (typeof item === "string") {
        return translate(item);
      }
      if (typeof item === "object") {
        return translate(item.key, item.values);
      }

      return "";
    },
    [translate]
  );

  return (
    <FormGenerator<T>
      formMustRegex={/^[a-zA-Z0-9 /?\n><;:,{}[\]\-_+=!@#$%^&*|'.\\()~`"]*$/}
      offsetScroll={90}
      translator={translatorFn}
      lang={currentLang.value as Language}
      {...props}
    />
  );
};
