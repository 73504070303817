import { useMemo } from "react";
import { useLocales } from "src/locales";

import * as Yup from "yup";
import {
  MainType,
  ViewTypes,
  useFromGeneratorRef,
} from "@novin-npm/shared-components-front";

export const addMailSettingNotificationValidation = Yup.object({
  host: Yup.string().required(),
  username: Yup.string().required(),
  password: Yup.string().required(),
  sender: Yup.string().required(),
  port: Yup.number(),
});
export type addMailSettingNotificationType = Yup.InferType<
  typeof addMailSettingNotificationValidation
>;

export const useAddMailSettingNotificationForm = () => {
  const { translate } = useLocales();
  const formRef = useFromGeneratorRef<addMailSettingNotificationType>();

  const initialValue: addMailSettingNotificationType = {
    host: "",
    username: "",
    password: "",
    sender: "",
    port: 0,
  };

  const formString = useMemo<MainType>(
    () => ({
      host: {
        type: ViewTypes.TextView,
        layoutProps: {
          xs: 12,
        },
        inputProps: {
          label: translate("NOTIFICATION_MANAGEMENT_HOST"),
        },
      },
      username: {
        type: ViewTypes.TextView,
        layoutProps: {
          xs: 12,
        },
        inputProps: {
          label: translate("LOG_DETAIL_USERNAME"),
        },
      },
      password: {
        type: ViewTypes.TextView,
        layoutProps: {
          xs: 12,
        },
        inputProps: {
          label: translate("USERS_NEW_USER_PASSWORD"),
        },
      },
      sender: {
        type: ViewTypes.TextView,
        layoutProps: {
          xs: 12,
        },
        inputProps: {
          label: translate("NOTIFICATION_MANAGEMENT_SENDER"),
        },
      },
      port: {
        type: ViewTypes.TextView,

        layoutProps: {
          xs: 12,
        },
        inputProps: {
          label: translate("NOTIFICATION_MANAGEMENT_PORT"),
        },
      },
    }),
    [translate]
  );
  return { formRef, formString, initialValue };
};
