// @ts-nocheck
/* eslint-disable */
/**
 * Notif\'s APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface SettingDto
 */
export interface SettingDto {
    /**
     * 
     * @type {string}
     * @memberof SettingDto
     */
    key?: SettingDtoKeyEnum;
    /**
     * 
     * @type {any}
     * @memberof SettingDto
     */
    value: any;
    /**
     * 
     * @type {boolean}
     * @memberof SettingDto
     */
    isActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof SettingDto
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof SettingDto
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof SettingDto
     */
    updatedAt?: Date;
}

/**
    * @export
    * @enum {string}
    */
export enum SettingDtoKeyEnum {
    MAIL = 'MAIL',
    FIREBASE = 'FIREBASE',
    WEB_PUSH = 'WEB_PUSH',
    SMS_KAVENEGAR = 'SMS_KAVENEGAR'
}


