import { useMemo } from "react";
// routes
import { ABSOLUTE_ROUTES } from "src/routes/paths";
// locales
import { useLocales } from "src/locales";
// components
import SvgColor from "src/components/svg-color";
//mui
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useMyProfile } from "src/hooks/query&mutation/profile/useMyProfile";
// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  user: icon("users-group-rounded"),
  organization: icon("ic_banking"),
  services: icon("services"),
  role: icon("ic_user"),
  security: icon("security"),
  banking: icon("ic_dashboard"),
  logs: icon("log"),
  log_services: icon("log_services"),
  notifications: icon("notification"),
  database: icon("database"),
  fileExplorer: icon("solar--library-bold"),
  blockChain: icon("blockChain"),
  accessControl: icon("access-control"),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { translate } = useLocales();
  const { data, isLoading } = useMyProfile();

  return useMemo(
    () => [
      {
        subheader: translate("MENU_DASHBOARD"),
        items: [
          {
            title: translate("MENU_DASHBOARD"),
            path: ABSOLUTE_ROUTES.BASE,
            icon: ICONS.banking,
          },
        ],
      },
      {
        subheader: translate("MENU_MANAGEMENT"),
        items: [
          {
            title: translate("MENU_USERS"),
            path: ABSOLUTE_ROUTES.USERS.BASE,
            icon: ICONS.user,
          },
          {
            title: translate("MENU_ORGANIZATION"),
            path: ABSOLUTE_ROUTES.ORGANIZATION.BASE,
            icon: ICONS.organization,
          },
          {
            title: translate("MENU_SERVICES"),
            path: ABSOLUTE_ROUTES.SERVICES.BASE,
            icon: ICONS.services,
          },
          {
            title: translate("MENU_ACCESS_CONTROL"),
            path: ABSOLUTE_ROUTES.ACCESS_CONTROL.BASE,
            icon: ICONS.security,
            children: [
              {
                title: translate("MENU_ROLES"),
                path: ABSOLUTE_ROUTES.ACCESS_CONTROL.ROLES.BASE,
              },
              {
                title: translate("MENU_PERMISSION_SETS"),
                path: ABSOLUTE_ROUTES.ACCESS_CONTROL.PERMISSION_SETS.BASE,
              },
            ],
          },
          {
            title: translate("MENU_FILE_EXPLORER"),
            path: ABSOLUTE_ROUTES.FILEeXPLORER.BASE,
            icon: ICONS.fileExplorer,
          },
          {
            title: translate("MENU_BLOCKCHAIN"),
            path: ABSOLUTE_ROUTES.BLOCKCHAIN.BASE,
            icon: ICONS.blockChain,
            children: [
              {
                title: translate("MENU_BLOCKCHAIN_MANAGEMENT"),
                path: ABSOLUTE_ROUTES.BLOCKCHAIN.MANAGEMENT,
              },
              {
                title: translate("MENU_BLOCKCHAIN_WALLET"),
                path: ABSOLUTE_ROUTES.BLOCKCHAIN.WALLET,
              },
              {
                title: translate("MENU_BLOCKCHAIN_CURRENCIES"),
                path: ABSOLUTE_ROUTES.BLOCKCHAIN.CURRENCIES,
              },
            ],
          },
          {
            title: translate("Notification"),
            path: ABSOLUTE_ROUTES.NOTIFICATION.BASE,
            icon: ICONS.notifications,
            children: [
              {
                title: translate("Management"),
                path: ABSOLUTE_ROUTES.NOTIFICATION.MANAGEMENT,
              },
              {
                title: translate("History"),
                path: ABSOLUTE_ROUTES.NOTIFICATION.HISTORY.BASE,
              },
            ],
          },
        ],
      },
      {
        subheader: translate("MENU_LOGS"),
        items: [
          {
            title: translate("MENU_REPORT"),
            path: ABSOLUTE_ROUTES.REPORTS.BASE,
            icon: <AssignmentIcon />,
          },
          {
            title: translate("MENU_DATABASE_FAILURE_LOG"),
            path: ABSOLUTE_ROUTES.DATABASE_LOGS.BASE,
            icon: ICONS.database,
          },
          {
            title: translate("MENU_LOGS"),
            path: ABSOLUTE_ROUTES.LOGS.BASE,
            icon: ICONS.logs,
          },
          {
            title: translate("MENU_MONITORING"),
            path: ABSOLUTE_ROUTES.MONITORING.BASE,
            icon: <ScreenSearchDesktopIcon />,
          },
        ],
      },
    ],
    [translate, data, isLoading]
  );
}
