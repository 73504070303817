import axios from "axios";
// config
import { LOG_API } from "src/config-global";
import {
  CreateFrontLogDto,
  DictionaryDto,
  LogDto,
  LogResponseDto,
  LogViewedDto,
  LogsControllerGetMany200ResponseMeta,
  ReportDto,
  ServiceDto,
  UpdateServiceDto,
} from "./models/logModel";
// ----------------------------------------------------------------------

export type GetListDto = {
  sort?: string;
  select?: string[];
  filter?: string[];
  or?: string[];
  page?: number;
  skip?: number;
  take?: number;
  excel?: string;
};

export type GetMonitorDto = {
  page?: number;
  skip?: number;
  take?: number;
};

export type GeneralList<T> = {
  data: Array<T>;
  meta: LogsControllerGetMany200ResponseMeta;
};

const instance = axios.create({
  baseURL: LOG_API,
});

export default {
  instance,

  //Dictionary
  getDictionary: (locale: string) =>
    instance
      .get<DictionaryDto[]>(`/dictionary/${locale}`)
      .then((res) => res.data),

  //DB_logs
  getDatabaseLogs: (params: { page: number; pageSize: number }) =>
    instance.get<LogDto[]>(`/logs/failed`, { params }).then((res) => res.data),

  //Logs
  getLogs: (params: GetListDto) =>
    instance
      .get<GeneralList<LogDto>>("/logs", { params })
      .then((res) => res.data),
  getSingleLog: (id: string) =>
    instance.get<LogResponseDto[]>(`/logs/${id}`).then((res) => res.data),
  postLog: (logData: CreateFrontLogDto) => instance.post("/logs", logData),

  //Monitors
  getLogsOfVisitedBalances: (params: GetMonitorDto) =>
    instance
      .get<GeneralList<LogViewedDto>>("/logs/visited-balances", { params })
      .then((res) => res.data),

  //Reports
  getReportTypes: (locale: string) =>
    instance.get<ReportDto[]>(`/reports/${locale}`).then((res) => res.data),

  getLogsOfReportType: (ReportType: string, params: GetListDto) =>
    instance
      .get<GeneralList<LogDto>>(`/logs/report/${ReportType}`, { params })
      .then((res) => res.data),

  //Services Logs
  getServicesLogs: (params: GetListDto) =>
    instance
      .get<GeneralList<ServiceDto>>("/services", { params })
      .then((res) => res.data),
  getSingleServiceLogs: (id: string) =>
    instance.get<ServiceDto>(`/services/${id}`).then((res) => res.data),
  putSettingOfServiceLog: (id: string, data: UpdateServiceDto) =>
    instance
      .put<UpdateServiceDto>(`/services/${id}`, data)
      .then((res) => res.data),
  deleteSingleServiceLogs: (
    id: string,
    data: { startDate: string | null | undefined; endDate: string }
  ) =>
    instance
      .delete(`/services/${id}`, { params: data })
      .then((res) => res.data),
};
