import { m } from "framer-motion";
// @mui
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// routes
import { RouterLink } from "src/routes/components";
// components
import { MotionContainer, varBounce } from "src/components/animate";
// assets
import { PageNotFoundIllustration } from "src/assets/illustrations";
import { useLocales } from "src/locales";
import Page from "src/components/page";
import { Container } from "@mui/material";
import { styled } from "@mui/system";

// ----------------------------------------------------------------------

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function NotFoundView() {
  const { translate } = useLocales();

  return (
    <Page title={translate("pageNotFound")}>
      <Container component={MotionContainer}>
        <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
          <m.div variants={varBounce().in}>
            <Typography variant="h3" paragraph>
              {translate("sorryPageNotFound")}
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography sx={{ color: "text.secondary" }}>
              {translate("sorryWeCouldntFindThePageYoureLookingForPerhapsYou")}
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <PageNotFoundIllustration
              sx={{ height: 260, my: { xs: 5, sm: 10 } }}
            />
          </m.div>

          <Button
            component={RouterLink}
            href="/"
            size="large"
            variant="contained"
          >
            {translate("goToHome")}
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
