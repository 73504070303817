import axios from "axios";
import { BLOCKCHAIN_API } from "src/config-global";
import { CompanyCurrentControllerCompanyUsers200ResponseMeta } from "./models/sentryModel";
import {
  BlockchainCreateDto,
  BlockchainsDto,
  BlockchainsDtoCoinTypeEnum,
  BlockchainUpdateDto,
  CreateCurrencyDto,
  CreateWalletDto,
  CurrencyDto,
  InitConfigTypeDto,
  ProcessBlockDto,
  SetHeightDto,
  TransactionDto,
  UpdateCurrencyDto,
  WalletDto,
  WalletUpdateDto,
} from "./models/blockchainModel";

export type GeneralList<T> = {
  data: Array<T>;
  meta: CompanyCurrentControllerCompanyUsers200ResponseMeta;
};

export type GetListDto = {
  sort?: string;
  select?: string[];
  filter?: string[];
  or?: string[];
  page?: number;
  skip?: number;
  take?: number;
  excel?: string;
};

const instance = axios.create({
  baseURL: BLOCKCHAIN_API,
});

export default {
  instance,
  getBlockChainsList: (params?: GetListDto) =>
    instance
      .get<GeneralList<BlockchainsDto>>("Blockchains", { params })
      .then((res) => res.data),
  getSingleBlockChain: (blockchainId: string) =>
    instance
      .get<BlockchainsDto>(`Blockchains/${blockchainId}`)
      .then((res) => res.data),
  getBlockChainCurrencies: ({
    blockchainId,
    params,
  }: {
    blockchainId: string;
    params?: GetListDto;
  }) =>
    instance
      .get<GeneralList<CurrencyDto>>(`currencies`, {
        params: {
          ...params,
          filter: [
            `blockchain.id||$eq||${blockchainId}`,
            ...(params?.filter || []),
          ],
        },
      })
      .then((res) => res.data),
  createCurrency: (data: CreateCurrencyDto) =>
    instance.post("currencies", data).then((res) => res.data),
  updateCurrency: (id: string, data: UpdateCurrencyDto) =>
    instance.patch(`currencies/${id}`, data).then((res) => res.data),
  getBlockChainTransactions: ({
    blockchainId,
    params,
  }: {
    blockchainId: string;
    params?: GetListDto;
  }) =>
    instance
      .get<GeneralList<TransactionDto>>(`transactions`, {
        params: {
          ...params,
          filter: [
            `blockchain.id||$eq||${blockchainId}`,
            ...(params?.filter || []),
          ],
        },
      })
      .then((res) => res.data),
  createBlockchain: (data: BlockchainCreateDto) =>
    instance.post("Blockchains", data).then((res) => res.data),
  initializeBlockchains: (data: InitConfigTypeDto[]) =>
    instance
      .post("/initialize", { initConfigDto: data })
      .then((res) => res.data),
  updateBlockchain: (data: BlockchainUpdateDto) =>
    instance.post("Blockchains/update", data).then((res) => res.data),
  getMainBlockChainHeight: (coinType: BlockchainsDtoCoinTypeEnum | null) =>
    instance
      .get(`Blockchains/height?coinType=${coinType}`)
      .then((res) => res.data),
  setMainBlockChainHeight: (data: SetHeightDto) =>
    instance.post("Blockchains/set-height", data).then((res) => res.data),
  setProcessBlock: (data: ProcessBlockDto) =>
    instance.post("Blockchains/process-block", data).then((res) => res.data),
  getBlock: ({
    index,
    coinType,
  }: {
    index: string;
    coinType: BlockchainsDtoCoinTypeEnum;
  }) =>
    instance
      .get(`Blockchains/block/${index}?coinType=${coinType}`)
      .then((res) => res.data),
  getTransaction: ({
    index,
    coinType,
  }: {
    index: string;
    coinType: BlockchainsDtoCoinTypeEnum;
  }) =>
    instance
      .get(`Blockchains/transaction?coinType=${coinType}&id=${index}`)
      .then((res) => res.data),
  getWalletsList: (params?: GetListDto) =>
    instance
      .get<GeneralList<any>>("wallet", { params })
      .then((res) => res.data),
  createWallet: (data: CreateWalletDto) =>
    instance.post("wallet", data).then((res) => res.data),
  updateWallet: ({
    walletId,
    data,
  }: {
    walletId: string;
    data: WalletUpdateDto;
  }) => instance.patch(`wallet/${walletId}`, data).then((res) => res.data),
  syncWallets: () => instance.post("wallet/sync").then((res) => res.data),
  getSingleWallet: (walletId: string) =>
    instance.get<WalletDto>(`wallet/${walletId}`).then((res) => res.data),
  getCurrencies: () =>
    instance
      .get<GeneralList<CurrencyDto>>(`currencies`)
      .then((res) => res.data),
};
