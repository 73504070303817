// @ts-nocheck
/* eslint-disable */
/**
 * Log API\'s
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateFrontLogDto
 */
export interface CreateFrontLogDto {
    /**
     * 
     * @type {string}
     * @memberof CreateFrontLogDto
     */
    action?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateFrontLogDto
     */
    alert?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateFrontLogDto
     */
    level: CreateFrontLogDtoLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateFrontLogDto
     */
    type: CreateFrontLogDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateFrontLogDto
     */
    situation: CreateFrontLogDtoSituationEnum;
    /**
     * 
     * @type {any}
     * @memberof CreateFrontLogDto
     */
    meta: any;
    /**
     * 
     * @type {string}
     * @memberof CreateFrontLogDto
     */
    serviceName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFrontLogDto
     */
    trackId: string;
    /**
     * 
     * @type {number}
     * @memberof CreateFrontLogDto
     */
    sequence: number;
    /**
     * 
     * @type {string}
     * @memberof CreateFrontLogDto
     */
    sourceName: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateFrontLogDtoLevelEnum {
    FATAL = 'fatal',
    ERROR = 'error',
    WARN = 'warn',
    INFO = 'info',
    DEBUG = 'debug',
    TRACE = 'trace'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateFrontLogDtoTypeEnum {
    HTTP = 'http',
    RPC = 'rpc',
    RMQ = 'rmq',
    PROCESS = 'process',
    UNKNOWN = 'unknown',
    PROCESS_ERROR = 'processError',
    PROCESS_PROMISE = 'processPromise',
    WS = 'ws'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateFrontLogDtoSituationEnum {
    REQUEST = 'request',
    RESPONSE = 'response',
    PROCESS = 'process',
    PUBLISH = 'publish',
    UNHANDLED_EXCEPTION = 'unhandledException',
    UNKNOWN = 'unknown'
}


