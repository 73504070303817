import { generatePath } from "react-router";
import { getAbsoluteRoute } from "src/utils/utils";

export const ROUTES = {
  BASE: "/",
  PAGE403: "403",
  PAGE404: "404",
  PAGE500: "500",

  // USERS
  USERS: {
    BASE: "users",
    SINGLE_USER: {
      BASE: ":userId",
      ORGANIZATION: "organizations",
      LOGS: "logs",
    },
  },

  //ORGANIZATION
  ORGANIZATION: {
    BASE: "organizations",
    SINGLE_ORGANIZATION: {
      BASE: ":organizationId",
      MEMBERS: "members",
      TEAMS: "teams",
      SERVICES: "services",
    },
  },
  //NOTIFICATION
  NOTIFICATION: {
    BASE: "notification",
    HISTORY: {
      BASE: "history",
      SMS: "sms",
      MAIL: "mail",
      MESSAGE: "message",
    },
    MANAGEMENT: "management",
    SINGLE_NOTIFICATION: {
      BASE: ":notificationId",
    },
  },

  //SERVICES
  SERVICES: {
    BASE: "services",
    SINGLE_SERVICE: {
      BASE: ":serviceId",
      PERMISSIONS: "permissions",
      RESOURCES: "resources",
      COMPANIES: "companies",
      LOG_CONFIG: "log-config",
      LOGS: "logs",
    },
  },

  //ROLES
  ACCESS_CONTROL: {
    BASE: "access-control",
    ROLES: {
      BASE: "roles",
      SINGLE_ROLE: {
        BASE: ":roleId",
        PERMISSIONS: "permissions",
        USERS: "users",
      },
    },
    PERMISSION_SETS: { BASE: "permission-sets", DETAIL: ":permissionSetId" },
  },
  BLOCKCHAIN: {
    BASE: "blockchains",
    MANAGEMENT: "management",
    SINGLE_BLOCKCHAIN: {
      BASE: ":blockchainId",
      CURRENCIES: "currencies",
      TRANSACTIONS: "transactions",
    },
    WALLET: "wallet",
    WALLET_DETAIL: "wallet/:walletId",
    CURRENCIES: "currencies",
  },

  //File EXPLORER
  FILEeXPLORER: {
    BASE: "file-explorer",
    DETAIL: ":folderId",
  },

  //LOGS
  LOGS: {
    BASE: "logs",
    LOG_DETAIL: ":trackId",
  },

  //MONITORING
  MONITORING: {
    BASE: "monitoring",
  },

  //REPORTS
  REPORTS: {
    BASE: "reports",
    REPORT_DETAIL: ":trackId",
  },

  //DB Logs
  DATABASE_LOGS: {
    BASE: "database-failure-logs",
  },
};

type RoutesArgsType =
  | { path: typeof ROUTES.BASE; params: {} }
  | {
      path: typeof ROUTES.USERS.SINGLE_USER.ORGANIZATION;
      params: { userId: string };
    }
  | {
      path: typeof ROUTES.USERS.SINGLE_USER.LOGS;
      params: { userId: string };
    }
  | {
      path: typeof ROUTES.ORGANIZATION.SINGLE_ORGANIZATION.MEMBERS;
      params: { organizationId: string };
    }
  | {
      path: typeof ROUTES.ORGANIZATION.SINGLE_ORGANIZATION.TEAMS;
      params: { organizationId: string };
    }
  | {
      path: typeof ROUTES.ORGANIZATION.SINGLE_ORGANIZATION.SERVICES;
      params: { organizationId: string };
    }
  | {
      path: typeof ROUTES.SERVICES.SINGLE_SERVICE.PERMISSIONS;
      params: { serviceId: string };
    }
  | {
      path: typeof ROUTES.SERVICES.SINGLE_SERVICE.RESOURCES;
      params: { serviceId: string };
    }
  | {
      path: typeof ROUTES.SERVICES.SINGLE_SERVICE.COMPANIES;
      params: { serviceId: string };
    }
  | {
      path: typeof ROUTES.SERVICES.SINGLE_SERVICE.LOG_CONFIG;
      params: { serviceId: string };
    }
  | {
      path: typeof ROUTES.SERVICES.SINGLE_SERVICE.LOGS;
      params: { serviceId: string };
    }
  | {
      path: typeof ROUTES.ACCESS_CONTROL.ROLES.SINGLE_ROLE.PERMISSIONS;
      params: { roleId: string };
    }
  | {
      path: typeof ROUTES.ACCESS_CONTROL.ROLES.SINGLE_ROLE.USERS;
      params: { roleId: string };
    }
  | {
      path: typeof ROUTES.ACCESS_CONTROL.PERMISSION_SETS.DETAIL;
      params: { permissionSetId: string };
    }
  | {
      path: typeof ROUTES.BLOCKCHAIN.SINGLE_BLOCKCHAIN.BASE;
      params: { blockchainId: string };
    }
  | {
      path: typeof ROUTES.BLOCKCHAIN.SINGLE_BLOCKCHAIN.CURRENCIES;
      params: { blockchainId: string };
    }
  | {
      path: typeof ROUTES.BLOCKCHAIN.SINGLE_BLOCKCHAIN.TRANSACTIONS;
      params: { blockchainId: string };
    }
  | {
      path: typeof ROUTES.BLOCKCHAIN.WALLET_DETAIL;
      params: { walletId: string };
    }
  | { path: typeof ROUTES.LOGS.LOG_DETAIL; params: { trackId: string } }
  | {
      path: typeof ROUTES.FILEeXPLORER.DETAIL;
      params: { folderId: string };
    }
  | {
      path: typeof ROUTES.NOTIFICATION.BASE;
      params: {};
    }
  | {
      path: typeof ROUTES.NOTIFICATION.HISTORY.BASE;
      params: {};
    };

type RoutesArgsWithParamsType = Extract<
  RoutesArgsType,
  { path: string; params: object }
>;

export const createPath = (args: RoutesArgsType) => {
  // Save some CPU power for routes without params
  if (!args.hasOwnProperty("params")) return args.path;
  return generatePath(args.path, (args as RoutesArgsWithParamsType).params);
};

export const ABSOLUTE_ROUTES = getAbsoluteRoute(ROUTES) as typeof ROUTES;
