import { useQuery } from "@tanstack/react-query";
import notifAxios, { GetListDto } from "src/axios/notifAxios";
import { QUERY_KEYS } from "../../../constants";

export const useGetAllNotification = ({
  tableState,
  isEnabled,
}: {
  tableState?: GetListDto;
  isEnabled?: boolean;
}) =>
  useQuery({
    queryKey: [QUERY_KEYS.NOTIFICATION.NOTIFICATION_LIST, tableState],
    queryFn: () => notifAxios.getAllNotification(tableState),
    enabled: tableState && !!Object.keys(tableState).length,
  });
