import { useMemo } from "react";
import { useLocales } from "src/locales";

import * as Yup from "yup";
import {
  MainType,
  ViewTypes,
  useFromGeneratorRef,
} from "@novin-npm/shared-components-front";

export const addWebPushNotificationValidation = Yup.object({
  vapidSubject: Yup.string().required(),
  vapidPublicKey: Yup.string().required(),
  vapidPrivateKey: Yup.string().required(),
});
export type addWebPushNotificationType = Yup.InferType<
  typeof addWebPushNotificationValidation
>;

export const useAddWebPushNotificationForm = () => {
  const { translate } = useLocales();
  const formRef = useFromGeneratorRef<addWebPushNotificationType>();

  const initialValue: addWebPushNotificationType = {
    vapidSubject: "",
    vapidPublicKey: "",
    vapidPrivateKey: "",
  };

  const formString = useMemo<MainType>(
    () => ({
      vapidSubject: {
        type: ViewTypes.TextView,
        layoutProps: {
          xs: 12,
        },
        inputProps: {
          label: translate("NOTIFICATION_MANAGEMENT_VAPID_SUBJECT"),
        },
      },
      vapidPublicKey: {
        type: ViewTypes.TextView,
        layoutProps: {
          xs: 12,
        },
        inputProps: {
          label: translate("NOTIFICATION_MANAGEMENT_VAPID_PUBLIC_KEY"),
        },
      },
      vapidPrivateKey: {
        type: ViewTypes.TextView,
        layoutProps: {
          xs: 12,
        },
        inputProps: {
          label: translate("NOTIFICATION_MANAGEMENT_VAPID_PRIVATE_KEY"),
        },
      },
    }),
    [translate]
  );
  return { formRef, formString, initialValue };
};
