import axios from "axios";
// config
import { NOTIF_API } from "src/config-global";
import {
  CreateSettingDto,
  EmailHistoryDto,
  MessageDto,
  SettingControllerGetMany200Response,
  SettingDto,
  SmsHistoryDto,
  UpdateSettingDto,
} from "./models/notifModel";

// ----------------------------------------------------------------------

export type GetListDto = {
  sort?: string;
  select?: string[];
  filter?: string[];
  or?: string[];
  page?: number;
  skip?: number;
  take?: number;
  excel?: string;
};

export type GetMonitorDto = {
  page?: number;
  skip?: number;
  take?: number;
};

export type GeneralList<T> = {
  data: Array<T>;
  meta: SettingControllerGetMany200Response["meta"];
};

const instance = axios.create({
  baseURL: NOTIF_API,
});

export default {
  instance,
  getAllNotification: (params?: GetListDto) =>
    instance
      .get<GeneralList<SettingDto>>("settings", { params })
      .then((res) => res.data),
  // get all history notification
  getAllEmailHistoryNotification: (params?: GetListDto) =>
    instance
      .get<GeneralList<EmailHistoryDto>>("email-histories", { params })
      .then((res) => res.data),
  getAllSmsHistoryNotification: (params?: GetListDto) =>
    instance
      .get<GeneralList<SmsHistoryDto>>("sms-histories", { params })
      .then((res) => res.data),
  // get all web_push and etc history
  getAllMessageHistoryNotification: (params?: GetListDto) =>
    instance
      .get<GeneralList<MessageDto>>("messages", { params })
      .then((res) => res.data),
  addNewNotification: (data: CreateSettingDto) =>
    instance.post("settings", data).then((res) => res?.data),
  updateNotification: (id: string, data: UpdateSettingDto) =>
    instance.patch(`settings/${id}`, data).then((res) => res.data),
};
