import { Card, Container, Button, Switch } from "@mui/material";
import Page from "src/components/page";
import { useLocales } from "src/locales";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import { ABSOLUTE_ROUTES } from "src/routes/paths";
import { CustomDataGrid } from "src/components/customComponents/dataGrid/CustomDataGrid";

import { useGetAllNotification } from "src/hooks/query&mutation/Notification/useGetAllNotification";
import { capitalize, tableStateToArray } from "src/utils/utils";
import { useState } from "react";
import {
  QueryType,
  useDataGridRef,
  FetchingDataEnum,
} from "@novin-npm/shared-components-front";
import moment from "moment";
import { GeneralDialogState } from "src/components/dialog/SimpleDialog";
import {
  CreateSettingDto,
  CreateSettingDtoKeyEnum,
  SettingDto,
  SettingDtoKeyEnum,
} from "src/axios/models/notifModel";
import { useUpdateNotification } from "src/hooks/query&mutation/Notification/useUpdateNotification";
import { useSettingsContext } from "src/components/settings";
import { useSnackbar } from "notistack";
import CreateNewNotificationDialog from "./dialog/CreateNewNotificationDialog";
const NotificationsManagement = () => {
  const initialSettingValue: SettingDto | CreateSettingDto = {
    key: SettingDtoKeyEnum.MAIL || CreateSettingDtoKeyEnum.MAIL,
    value: {},
    isActive: false,
    id: "",
    createdAt: new Date(),
  };
  const { translate, currentLang } = useLocales();
  const { themeStretch } = useSettingsContext();

  const tableRef = useDataGridRef();
  const [tableState, setTableState] = useState<QueryType>();
  const { enqueueSnackbar } = useSnackbar();
  const [addNotificationDialog, setAddNotificationDialog] = useState<
    GeneralDialogState<SettingDto>
  >({
    show: false,
    data: initialSettingValue,
  });
  const { data, isLoading, isError, isFetching, refetch } =
    useGetAllNotification({
      tableState: tableStateToArray(tableState),
    });

  const { updateNotification } = useUpdateNotification({});
  return (
    <Page title={translate("NOTIFICATION_MANAGEMENT")}>
      <Container
        maxWidth={themeStretch ? false : "xl"}
        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <CustomBreadcrumbs
          heading={translate("NOTIFICATION_MANAGEMENT")}
          links={[
            {
              name: translate("MENU_DASHBOARD"),
              href: ABSOLUTE_ROUTES.BASE,
            },
            {
              name: translate("NOTIFICATION_MANAGEMENT"),
            },
          ]}
          action={
            <Button
              disabled={false}
              size="large"
              variant="contained"
              onClick={() =>
                setAddNotificationDialog({
                  show: true,
                  data: initialSettingValue,
                })
              }
            >
              {translate("NOTIFICATION_MANAGEMENT_ADD_NEW")}
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <Card
          sx={{
            flexGrow: 1,
            "& .MuiDataGrid-root": {
              border: 0,
            },
            mt: 5,
          }}
        >
          <CustomDataGrid
            tableRef={tableRef}
            columns={[
              {
                field: "key",
                type: "singleSelect",
                valueOptions: Object.values(CreateSettingDtoKeyEnum)?.map(
                  (item) => ({
                    label: capitalize(item?.split("_").join(" ")),
                    value: item,
                  })
                ),
                headerName: translate("REPORTS_TYPE"),
                flex: 1,
                minWidth: 100,
                valueFormatter: ({ value }) =>
                  capitalize(value?.split("_").join(" ")),
              },
              {
                field: "value",
                type: "string",
                headerAlign: "left",
                align: "left",
                valueGetter: ({ value }) => JSON.stringify(value),
                valueFormatter: ({ value }) =>
                  JSON.stringify(JSON.parse(value), null, 1),
                headerName: translate("value"),
                flex: 2,
                minWidth: 200,
              },
              {
                field: "updatedAt",
                type: "date",
                headerName: translate("SERVICE_UPDATE_AT"),
                flex: 1,
                minWidth: 100,
                valueGetter: (params) => new Date(params.value),
                valueFormatter: ({ value }) =>
                  moment(value)
                    .locale(currentLang.value)
                    .format("YYYY/MM/DD , HH:MM"),
              },
              {
                field: "createdAt",
                type: "date",
                headerName: translate("SERVICE_CREATED_AT"),
                flex: 1,
                minWidth: 100,
                valueGetter: (params) => new Date(params.value),
                valueFormatter: ({ value }) =>
                  moment(value)
                    .locale(currentLang.value)
                    .format("YYYY/MM/DD , HH:MM"),
              },
              {
                field: "isActive",
                headerName: translate("SERVICE_STATUS"),
                flex: 1,
                minWidth: 50,
                renderCell: ({ row }) => (
                  <Switch
                    checked={row.isActive}
                    onChange={(event) => {
                      let filteredNotificationData =
                        data?.data.find(
                          (noti) =>
                            noti?.key === row?.key && noti?.isActive === true
                        ) || {};
                      if (
                        event.target.checked === true &&
                        Object.values(filteredNotificationData).length
                      ) {
                        enqueueSnackbar(
                          translate(
                            "NOTIFICATION_HISTORY_AlREADY_HAD_ACTIVE_NOTIFICATION"
                          )
                        );
                      } else
                        updateNotification({
                          notificationId: row.id,
                          data: { isActive: event.target.checked },
                        });
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                ),
              },
            ]}
            isFetching={isFetching}
            dataFetching={{
              type: FetchingDataEnum.MANUAL,
              loading: isLoading,
              error: isError,
              onRetry: refetch,
              data: {
                items: data?.data || [],
                rowCount: data?.meta?.total || 0,
              },
            }}
            onRowClick={(params, event) => {
              setAddNotificationDialog({
                show: true,
                data: params.row,
              });
            }}
            disableRowSelectionOnClick={true}
            onQueryUpdate={(query) => {
              setTableState(query);
            }}
            pageSizeOptions={[10, 20, 50, 100]}
            slotProps={{
              row: {
                style: {
                  cursor: "pointer",
                },
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
          />
        </Card>
      </Container>
      {addNotificationDialog.show && (
        <CreateNewNotificationDialog
          addNotificationDialog={addNotificationDialog}
          setAddNotificationDialog={setAddNotificationDialog}
        />
      )}
    </Page>
  );
};
export default NotificationsManagement;
