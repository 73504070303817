import { useLocation, matchPath } from "react-router-dom";

// ----------------------------------------------------------------------

type ReturnType = boolean;

export function useActiveLink(path: string, deep = true): ReturnType {
  const { pathname } = useLocation();

  if (path === "/") {
    return !!matchPath({ path: path, end: true }, pathname);
  } else {
    return path ? !!matchPath({ path: path, end: false }, pathname) : false;
  }
}
