import { useKeycloak } from "@react-keycloak/web";
import { LoadingScreen } from "src/components/loading-screen";
import { useMyProfile } from "src/hooks/query&mutation/profile/useMyProfile";

type PermissionProviderPropsType = {
  children: JSX.Element[] | JSX.Element;
};
export const PermissionProvider = ({
  children,
}: PermissionProviderPropsType) => {
  const { keycloak, initialized } = useKeycloak();
  const { data, isLoading } = useMyProfile(
    initialized && keycloak.authenticated
  );

  if (!initialized) {
    return <LoadingScreen />;
  }

  if (!keycloak.authenticated) {
    keycloak.login();
    return <LoadingScreen />;
  }

  // if (isLoading || (keycloak.authenticated && !data?.permissions.length)) {
  //   return <LoadingScreen />;
  // }

  return <>{children}</>;
};
