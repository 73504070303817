import { GridFilterItem } from "@mui/x-data-grid";
import {
  NestedRouteObject,
  ObjectType,
  PrepareDataForAutoCompleteProps,
} from "src/types/common";
import moment from "moment";
import { QueryType } from "@novin-npm/shared-components-front";
import { useEffect, useRef } from "react";
import sentryAxios from "src/axios/sentryAxios";
import logAxios from "src/axios/logAxios";
import { CreateFrontLogDtoLevelEnum } from "src/axios/models/logModel";
import notifAxios from "src/axios/notifAxios";
import spaceAxios from "src/axios/spaceAxios";
import { IFileFilters } from "src/pages/fileExplorer/types";
import blockchainAxios from "src/axios/blockchainAxios";
import BigNumber from "bignumber.js";
import { SxProps, Skeleton } from "@mui/material";
import axios from "./axios";

export const fix_axios_token = (token: string) => {
  sentryAxios.instance.defaults.headers.common.Authorization = token;
  logAxios.instance.defaults.headers.common.Authorization = token;
  notifAxios.instance.defaults.headers.common.Authorization = token;
  spaceAxios.instance.defaults.headers.common.Authorization = token;
  blockchainAxios.instance.defaults.headers.common.Authorization = token;
};

export const capitalize = (stringInput: string) => {
  if (typeof stringInput !== "string") return "";
  return (
    stringInput.charAt(0).toUpperCase() +
    stringInput.slice(1).toLocaleLowerCase()
  );
};
type NestedArrayReturnType = {
  cause: string;
  reason: string;
  additionalReson: string;
  indexOfNested: string;
};
const handleNestedArrayErrorMessages = (
  nestedErrorMsg = "",
  indexOfNested = ""
): NestedArrayReturnType => {
  const errorMsgArray = nestedErrorMsg.split(":");
  const [cause, reason, ...restOfErrorMsgArray] = errorMsgArray;
  if (reason !== "NESTED") {
    return {
      cause: capitalize(cause || ""),
      reason: reason || "",
      additionalReson: restOfErrorMsgArray[0] || "",
      indexOfNested,
    };
  }
  const [indexOfNestedArray, ...newNestedErrorArray] = restOfErrorMsgArray;
  const newNestedString = newNestedErrorArray?.join(":");
  return handleNestedArrayErrorMessages(newNestedString, indexOfNestedArray);
};

export const handleArrayErrorMessages = (error = []) =>
  error.map((errorMsg) => handleNestedArrayErrorMessages(errorMsg));

export const prepareDataForAutoComplete = ({
  array,
  keyProp,
  valueProp,
  otherPropsFn,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
PrepareDataForAutoCompleteProps) => {
  const data: ObjectType = {};
  if (array) {
    array.forEach((item) => {
      if (typeof item === "string") {
        data[item] = {
          label: item,
        };
      } else {
        let key;
        let value;

        if (typeof keyProp === "function") {
          key = keyProp(item);
        } else {
          key = item[keyProp];
        }

        if (typeof valueProp === "function") {
          value = valueProp(item);
        } else {
          value = item[valueProp];
        }

        let otherProps = {};
        if (typeof otherPropsFn === "function") {
          otherProps = otherPropsFn(item);
        }

        data[key] = {
          label: value,
          ...otherProps,
        };
      }
    });
  }

  return data;
};

const convertOperatorToNestApi = (operator: string): string => {
  const map: { [key: string]: string } = {
    // string
    contains: "$contL",
    equals: "$eq",
    startsWith: "$startsL",
    endsWith: "$endsL",
    isEmpty: "$isnull", // no value
    isNotEmpty: "$notnull", // no value
    isAnyOf: "$in", // multiple
    // date
    D_is: "$between",
    D_after: "$gt",
    D_onOrAfter: "$gte",
    D_before: "$lt",
    D_onOrBefore: "$lte",
    // date time
    is: "$eq",
    not: "$ne",
    after: "$gt",
    onOrAfter: "$gte",
    before: "$lt",
    onOrBefore: "$lte",
    // number
    "=": "$eq",
    "!=": "$ne",
    ">": "$gt",
    ">=": "$gte",
    "<": "$lt",
    "<=": "$lte",
  };
  return map[operator] || operator;
};
const filterModelMap = (item: GridFilterItem) => {
  let filterString = `${item.field}||${convertOperatorToNestApi(
    item.operator || ""
  )}`;
  if (
    [
      "D_is",
      "D_not",
      "D_after",
      "D_onOrAfter",
      "D_before",
      "D_onOrBefore",
    ].includes(item.operator || "")
  ) {
    filterString += getDateForFilter(item);
  } else if (moment.isDate(item.value)) {
    filterString += `||${item.value.toISOString()}`;
  } else if (item.operator === "isAnyOf") {
    filterString += `||${item.value.join(",")}`;
  } else if (!["isEmpty", "isNotEmpty"].includes(item.operator || "")) {
    filterString += `||${item.value}`;
  }
  return filterString;
};

const getDateForFilter = (item: GridFilterItem) => {
  switch (item.operator) {
    case "D_is":
      return `||${moment(item.value).startOf("day").toISOString()},${moment(
        item.value
      )
        .endOf("day")
        .toISOString()}`;
    case "D_after":
      return `||${moment(item.value).endOf("day").toISOString()}`;
    case "D_onOrAfter":
      return `||${moment(item.value).startOf("day").toISOString()}`;
    case "D_before":
      return `||${moment(item.value).startOf("day").toISOString()}`;
    case "D_onOrBefore":
      return `||${moment(item.value).endOf("day").toISOString()}`;
  }
};

const tableColumnFilterFn = (item: GridFilterItem) => {
  if (
    item.operator === "isAnyOf" &&
    Array.isArray(item.value) &&
    item.value.length === 0
  ) {
    return false;
  }

  if (["isEmpty", "isNotEmpty"].includes(item.operator || "")) {
    return true;
  }

  return !!item.value;
};

export const tableStateToArray = (tableState: QueryType | undefined) => {
  if (!tableState) {
    return {};
  }
  const sortModel =
    tableState.sortModel?.map((item) => `${item.field},${item.sort}`) || [];
  const filters = tableState.filterModel?.items
    ?.filter(tableColumnFilterFn)
    .map(filterModelMap);

  return {
    page: parseInt(tableState.page.toString()) + 1,
    take: parseInt(tableState.pageSize.toString()),
    ...(tableState.sortModel?.length || 0 >= 1
      ? {
          sort: sortModel.length === 1 ? sortModel[0] : undefined,
        }
      : {}),
    ...(filters && (filters?.length || 0) !== 0
      ? {
          ...(tableState.filterModel?.logicOperator === "or"
            ? {
                filter: filters[0],
                or: filters.slice(1),
              }
            : {
                filter: filters,
              }),
        }
      : {}),
  };
};

const removeAdditionalSlashes = (path: string) => path.replace(/\/+/gi, "/");
export const getAbsoluteRoute = (obj: NestedRouteObject, base = "") => {
  const newobj: NestedRouteObject = {};
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object") {
      newobj[key] = getAbsoluteRoute(
        obj[key],
        removeAdditionalSlashes(`${base}/${obj.BASE}`)
      );
    } else if (typeof obj[key] === "string") {
      let value;
      if (key === "BASE") {
        value = obj[key] === "/" ? "/" : `/${base}/${obj[key]}`;
      } else {
        value = `/${base}/${obj.BASE}/${obj[key]}`;
      }
      newobj[key] = removeAdditionalSlashes(value);
    }
  });
  return newobj;
};

function stringToColor(string: string) {
  const colors = [
    "primary.main",
    "error.main",
    "info.main",
    "warning.main",
    "secondary.main",
    "success.main",
  ];
  const firstLetterCode = string.charCodeAt(0);
  return colors[firstLetterCode % colors.length];
}

export function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${(name.split(" ")[0][0] || "").toUpperCase()}`,
  };
}

export const convertEditorHtmlToSimpleHtml = (html: string) => {
  let result = html.replace(
    /<img([^>]*)src="([^"]+)"([^>]*)[^>]*alt="([^"]*)"([^>]*)>/gim,
    (match, p1, p2, p3, p4, p5) =>
      `<img${p1}src="${
        import.meta.env.VITE_DMS_URL
      }files/download/${p4}"${p3}alt="${p4}"${p5}>`
  );
  result = result.replace(/style\s*=\s*"([^"]*)"/gim, (_, data, _1, a) => {
    let percent = "";
    if (data.split(" ")[0] === "width:") {
      percent = data.split(" ")[1].split(";")[0];
    }
    return `width="${percent}"`;
  });
  return result;
};

export const convertSimpleHtmlToEditorHtml = async (
  html: string,
  token: string
) => {
  const images: {
    id: string;
    url: string;
    base64: string;
  }[] = [];
  html.replace(
    /<img([^>]*)src="([^"]+)"([^>]*)[^>]*alt="([^"]*)"([^>]*)>/gim,
    (match, preAt, url, betAt, id, AfterAt) => {
      images.push({
        id,
        url,
        base64: "",
      });
      return `<img${preAt}src="${url}"${betAt}alt="${id}"${AfterAt}>`;
    }
  );
  await Promise.all(
    images.map(async (image) => {
      const response = await axios.get<Buffer>(image.url, {
        responseType: "arraybuffer",
        headers: { Authorization: `Bearer ${token}` },
      });
      let binary = "";
      let bytes = new Uint8Array(response.data);
      const blob = new Blob([bytes]);
      let len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      image.base64 = `data:${
        response.headers["content-type"]
      };base64,${window.btoa(binary)}`;
    })
  );

  return html.replace(
    /<img([^>]*)src="([^"]+)"([^>]*)[^>]*alt="([^"]*)"([^>]*)>/gim,
    (match, preAt, url, betAt, id, AfterAt) => {
      const image = images.find((image) => image.id === id);
      return `<img${preAt}src="${image?.base64}"${betAt}alt="${id}"${AfterAt}>`;
    }
  );
};

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const flatObject = (obj: Object, prefix: string, result: any) => {
  const NewPrefix = prefix ? `${prefix}_` : "";
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === "object") {
      flatObject(value, `${NewPrefix}${key}`, result);
    } else {
      result[`${NewPrefix}${key}`] = value;
    }
  });
};

export const convertToFlatObject = (obj: Object) => {
  const result = {};
  const object = JSON.parse(JSON.stringify(obj));
  flatObject(object, "", result);
  return result;
};

export const get_log_color = (status: string) => {
  switch (status.toLowerCase()) {
    case CreateFrontLogDtoLevelEnum.ERROR:
    case CreateFrontLogDtoLevelEnum.TRACE:
      return "error";
    case CreateFrontLogDtoLevelEnum.DEBUG:
      return "primary";
    case CreateFrontLogDtoLevelEnum.WARN:
      return "warning";
    case CreateFrontLogDtoLevelEnum.FATAL:
    case CreateFrontLogDtoLevelEnum.INFO:
      return "info";
    default:
      return "default";
  }
};

export const decimalThousandSeparator = (input: number | string) => {
  const numberWithoutDecimalPart = Number.isInteger(input)
    ? input.toString()
    : input.toString().split(".")[0];
  const decimalPart = Number.isInteger(input)
    ? 0
    : input.toString().split(".")[1];
  return `${numberWithoutDecimalPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${
    decimalPart ? `.${decimalPart}` : ""
  }`;
};

export const convertFilterObjToArray = (filterObj: IFileFilters) => {
  const filters: string[] = [];
  if (!filterObj) {
    return [];
  }
  if (filterObj.name) {
    filters.push(`originalName||$contL||${filterObj.name}`);
  }
  if (filterObj.type.length > 0) {
    filterObj.type.forEach((item) => {
      if (item === "folder") {
        filters.push("type||$eq||FOLDER");
      } else {
        filters.push(`mimeType||$contL||${item}`);
      }
    });
  }
  return filters;
};

export const fixed_decimal_amount = (
  value: string,
  digit: number = 0,
  shouldFormat = true
) => {
  const rounded = new BigNumber(value)
    .toFixed(digit, BigNumber.ROUND_DOWN)
    .toString();

  if (!shouldFormat) {
    return rounded;
  }

  return decimalThousandSeparator(rounded);
};
